import type { State } from "@/reducers/domain";
import type { RubyBudgetPlan, RubyCampaignId } from "@/services/backend/RubyData";
import type { MetricsReport } from "@/utilities/metrics";
import { aggregateAsyncData } from "@/utilities/requests";
import { createSelector } from "@reduxjs/toolkit";
import moment from "moment";
import { selectCampaign, selectCampaignActiveBudget, selectCampaignBudgetAllocated } from "./campaignSelectors";
import { selectCampaignSummary } from "./reportsSelectors";

export interface BudgetPacing {
    budget: RubyBudgetPlan;
    metrics: MetricsReport;
    allocatedBudget: number;
    unallocatedBudget: number;
    unspentBudget: number;
    spentBudget: number;
    idealDailySpend: number;
    actualDailySpend: number;
}

const selectBudgetMetricsFilter = createSelector([selectCampaignActiveBudget, selectCampaign], (budget, campaign) => {
    return {
        teamId: campaign.data?.teamId,
        campaignId: campaign.data?.id,
        from: budget.data?.start,
        to: budget.data?.end,
    };
});

function selectBudgetMetrics(state: State, campaignId: RubyCampaignId) {
    return selectCampaignSummary(state, selectBudgetMetricsFilter(state, campaignId));
}

export const selectBudgetPacing = createSelector(
    [selectCampaignActiveBudget, selectCampaignBudgetAllocated, selectBudgetMetrics],
    (budget, allocatedBudget, budgetMetrics) => {
        const start = moment.utc(budget.data?.start);
        return {
            ...aggregateAsyncData(budget, budgetMetrics),
            data:
                budget.success && budget.data && budgetMetrics.success && budgetMetrics.data
                    ? {
                          budget: budget.data,
                          allocatedBudget,
                          unallocatedBudget: budget.data.totalBudget - allocatedBudget,
                          idealDailySpend: budget.data.totalBudget / budget.data.lengthInDays,
                          actualDailySpend:
                              budgetMetrics.data.spend /
                              Math.abs(moment.utc().startOf("hour").diff(start, "days", true)),
                          metrics: budgetMetrics.data,
                          unspentBudget: budget.data.totalBudget - budgetMetrics.data.spend,
                          spentBudget: budgetMetrics.data.spend,
                      }
                    : null,
        };
    }
);
