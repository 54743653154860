import union from "lodash/union";
import moment from "moment";
import type { AsyncData, ReportAsyncData } from "../reducers/domain";
import { type TargetingKeyword } from "../selectors/keywordsSelectors";
import {
    RubyKeywordMatchType,
    RubyKeywordRank,
    RubyKeywordStatus,
    RubyPurpose,
    type RubyCampaignId,
    type RubyCountry,
    type RubyIOSApp,
    type RubyKeywordRankReport,
    type RubyMetricsReport,
    type RubyMetricsReportGrouping,
    type RubyRegionsReport,
} from "../services/backend/RubyData";
import { formatCurrency } from "./currency";
import { formatNumber, formatPercent, mapAverage, mapMax, mapMin, safeDivide, sumReducer } from "./number";
import { aggregateAsyncData } from "./requests";
import {
    ReportGranularity,
    aggregateAtGranularity,
    generateEmptyTemporalReport,
    tsToStartOfGranularity,
    type Temporal,
} from "./time";

export interface MetricsReport extends RubyMetricsReport {
    // impressions: number;
    // installs: number;
    // latOnInstalls: number;
    // newDownloads: number;
    // latOffInstalls: number;
    // redownloads: number;
    // spend: number;
    // taps: number;
    costPerDownload: number;
    costPerTap: number;
    costPerImpression: number;
    tapThroughRate: number;
    conversionRate: number;
}

export interface TemporalMetricsReport extends Temporal, MetricsReport {
    // date: number;
    // granularity: ReportGranularity;
}

export interface CampaignMetricsReport extends MetricsReport {
    campaignId: RubyCampaignId;
}

export interface AppMetricsReport extends MetricsReport {
    appId: RubyIOSApp["trackId"];
}

export interface RegionsMetricsReport extends MetricsReport {
    region: string;
}

export interface PurposeMetricsReport extends MetricsReport {
    purpose: RubyPurpose;
}

export interface KeywordMetricsReport extends MetricsReport {
    keywordId: number;
}
export interface KeywordMetricsReportDetails extends KeywordMetricsReport {
    text: string;
    match: RubyKeywordMatchType;
    status: RubyKeywordStatus;
    countries: RubyCountry[];
    purpose: RubyPurpose;
    targeting: TargetingKeyword;
}

export interface SearchTermReport extends MetricsReport {
    searchTerm: string;
}

export interface TemporalKeywordMetricsReport extends TemporalMetricsReport, KeywordMetricsReport {}

export type MetricKey = keyof MetricsReport;

export type RegionsReport = RegionsMetricsReport[];

export function calculateAdditionalMetrics(rubyReport: RubyMetricsReport): MetricsReport {
    return {
        ...rubyReport,
        costPerDownload: safeDivide(rubyReport.spend, rubyReport.installs),
        costPerTap: safeDivide(rubyReport.spend, rubyReport.taps),
        costPerImpression: safeDivide(rubyReport.spend, rubyReport.impressions),
        tapThroughRate: safeDivide(rubyReport.taps, rubyReport.impressions),
        conversionRate: safeDivide(rubyReport.installs, rubyReport.taps),
    };
}

export function extractRegionsReport(rubyData: RubyRegionsReport, activeRegions: RubyCountry[]): RegionsReport {
    const regions = union(
        rubyData.map((d) => d.key),
        activeRegions
    );
    const report: RegionsReport = regions.map((region) => ({
        region,
        ...emptyMetricsReport(),
    }));
    rubyData.forEach((curr) => {
        const existingIdx = report.findIndex((d) => d.region === curr.key);
        const regionReport = { region: curr.key, ...calculateAdditionalMetrics(curr.value) };
        if (existingIdx > -1) {
            report[existingIdx] = regionReport;
        } else {
            report.push(regionReport);
        }
    });
    return report;
}

export function emptyMetricsReport(): MetricsReport {
    return {
        spend: 0,
        installs: 0,
        impressions: 0,
        taps: 0,
        latOnInstalls: 0,
        latOffInstalls: 0,
        newDownloads: 0,
        redownloads: 0,
        conversionRate: 0,
        costPerDownload: 0,
        costPerImpression: 0,
        costPerTap: 0,
        tapThroughRate: 0,
    };
}

export function aggregateMetrics(metrics: RubyMetricsReport[]): MetricsReport {
    const totalMetrics: RubyMetricsReport = emptyMetricsReport();
    for (const key in totalMetrics) {
        totalMetrics[key] = metrics?.reduce(sumReducer(key), 0) ?? 0;
    }
    return calculateAdditionalMetrics(totalMetrics);
}

export function getKeywordSummaryFromRequest(
    report: ReportAsyncData<RubyMetricsReportGrouping[]>,
    targeting: AsyncData<TargetingKeyword[]>,
    blacklist?: RubyPurpose[]
): ReportAsyncData<KeywordMetricsReportDetails[]> {
    const req = aggregateAsyncData(report, targeting);
    return {
        ...report,
        ...req,
        data: req.success ? getKeywordSummary(req.data[0], req.data[1], blacklist) : null,
    };
}

export function getKeywordSummary(
    report: RubyMetricsReportGrouping[],
    targeting: TargetingKeyword[],
    blacklist: RubyPurpose[] = [RubyPurpose.KEYWORD_DISCOVERY]
): KeywordMetricsReportDetails[] {
    // This object could be huge and we don't want to keep `.find`ing on an array, so Map it to make it easy
    const metricsMap = new Map<RubyMetricsReportGrouping["key"], RubyMetricsReport[]>();
    report.forEach((metrics) => {
        if (metricsMap.has(metrics.key)) {
            const reports = metricsMap.get(metrics.key);
            reports.push(metrics.value);
            metricsMap.set(metrics.key, reports);
        } else {
            metricsMap.set(metrics.key, [metrics.value]);
        }
    });

    const targetingMap = new Map<TargetingKeyword["id"], TargetingKeyword>();
    targeting.forEach((keyword) => {
        if (!blacklist.includes(keyword.purpose)) {
            targetingMap.set(keyword.id, keyword);
        }
    });

    const summary = [...targetingMap.keys()].reduce((summary, keywordId) => {
        if (targetingMap.has(keywordId)) {
            summary.push({
                ...aggregateMetrics(metricsMap.get(keywordId.toString()) ?? []),
                keywordId,
                text: targetingMap.get(keywordId)?.text ?? "",
                match: targetingMap.get(keywordId)?.match ?? RubyKeywordMatchType.EXACT,
                status: targetingMap.get(keywordId)?.status ?? RubyKeywordStatus.DISABLED,
                countries: targetingMap.get(keywordId)?.countries ?? [],
                purpose: targetingMap.get(keywordId)?.purpose ?? null,
                targeting: targetingMap.get(keywordId),
            });
        }
        return summary;
    }, [] as KeywordMetricsReportDetails[]);
    return summary;
}

export function aggregateMetricsAtGranularity(
    reports: TemporalMetricsReport[],
    granularity: ReportGranularity,
    from?: number,
    to?: number
): TemporalMetricsReport[] {
    return aggregateAtGranularity<TemporalMetricsReport>(
        reports,
        granularity,
        emptyMetricsReport(),
        aggregateMetrics,
        from,
        to
    );
}

export function formatMetrics(data: MetricsReport, currency: string): { [key in keyof MetricsReport]: string } {
    return {
        impressions: getMetricDisplayValue(data, "impressions", currency),
        taps: getMetricDisplayValue(data, "taps", currency),
        installs: getMetricDisplayValue(data, "installs", currency),
        spend: getMetricDisplayValue(data, "spend", currency),
        costPerTap: getMetricDisplayValue(data, "costPerTap", currency),
        costPerImpression: getMetricDisplayValue(data, "costPerImpression", currency),
        costPerDownload: getMetricDisplayValue(data, "costPerDownload", currency),
        tapThroughRate: getMetricDisplayValue(data, "tapThroughRate", currency),
        conversionRate: getMetricDisplayValue(data, "conversionRate", currency),
        latOffInstalls: getMetricDisplayValue(data, "latOffInstalls", currency),
        latOnInstalls: getMetricDisplayValue(data, "latOnInstalls", currency),
        newDownloads: getMetricDisplayValue(data, "newDownloads", currency),
        redownloads: getMetricDisplayValue(data, "redownloads", currency),
    };
}

export function getMetricValue(data: MetricsReport, key: MetricKey): number {
    if (data) {
        return data[key];
    }
    return 0;
}

export function getMetricDisplayValue(data: MetricsReport, key: MetricKey, currency: string): string {
    const value = getMetricValue(data, key);
    return formatMetricValue(value, key, currency);
}

export function formatMetricValue(value: number, key: MetricKey, currency: string): string {
    switch (key) {
        case "spend":
        case "costPerDownload":
        case "costPerImpression":
        case "costPerTap": {
            return formatCurrency(value, currency);
        }
        case "conversionRate":
        case "tapThroughRate": {
            return formatPercent(value);
        }
        default: {
            return formatNumber(value);
        }
    }
}

/*
 * ASA Docs:
 *     The ranking of your app in terms of impression share compared
 *     to other apps in the same countries or regions. The rank
 *     displays as numbers from ONE to FIVE or GREATER_THAN_FIVE,
 *     with ONE being the highest rank.
 */
const ranks = [
    RubyKeywordRank.UNKNOWN,
    RubyKeywordRank.GREATER_THAN_FIVE,
    RubyKeywordRank.FIVE,
    RubyKeywordRank.FOUR,
    RubyKeywordRank.THREE,
    RubyKeywordRank.TWO,
    RubyKeywordRank.ONE,
];
export function getKeywordRankAsValue(rank: RubyKeywordRank) {
    return ranks.indexOf(rank);
}

export function getKeywordValueAsRank(value: number) {
    if (value > ranks.length - 1) {
        return RubyKeywordRank.UNKNOWN;
    }
    return ranks[value];
}

export function getKeywordRankByValue(value: number) {
    return ranks[value] ?? RubyKeywordRank.GREATER_THAN_FIVE;
}

/**
 * Assumes the 2 data sets are in the same granularity
 */
export function combineTemporalReports(...reportSets: Temporal[][]): Temporal[] {
    const finalReports = [] as Temporal[];

    const flattenedReports = reportSets.flat();

    const groupedByDate = {} as Record<number, Temporal[]>;
    flattenedReports.forEach((report) => {
        const date = report.date;
        if (!groupedByDate[date]) {
            groupedByDate[date] = [];
        }
        groupedByDate[date].push(report);
    });

    for (const date in groupedByDate) {
        const reports = groupedByDate[date];
        const result = reports.reduce((result, report) => {
            return {
                ...result,
                ...report,
            };
        }, {});
        finalReports.push(result as Temporal);
    }

    // This return type really should be a union of the argument types that extend Temporal,
    // rather than Temporal itself, but I can't work out the TS for that
    return finalReports;
}

/**
 * @param reportSets Reports must be of the same granularity already
 */
export function aggregateTemporalMetricsReports(...reportSets: TemporalMetricsReport[][]): TemporalMetricsReport[] {
    const finalReports = [] as TemporalMetricsReport[];
    let granularity = ReportGranularity.HOURLY;

    const flattenedReports = reportSets.flat();

    const groupedByDate = {} as Record<number, TemporalMetricsReport[]>;
    flattenedReports.forEach((report) => {
        const date = report.date;
        granularity = report.granularity;
        if (!groupedByDate[date]) {
            groupedByDate[date] = [];
        }
        groupedByDate[date].push(report);
    });

    for (const date in groupedByDate) {
        const reports = groupedByDate[date];
        const result = {
            ...aggregateMetrics(reports),
            date: reports[0].date,
            granularity,
        };
        finalReports.push(result);
    }

    return finalReports;
}

function emptyRankReport(): RubyKeywordRankReport {
    return {
        reportRef: 0,
        searchTerm: "",
        teamId: 0,
        orgRef: 0,
        adamRef: 0,
        appName: "",
        country: "",
        period: 0,
        lowImpressionShare: 0,
        highImpressionShare: 0,
        rank: RubyKeywordRank.UNKNOWN,
        searchPopularity: 0,
    };
}

/**
 * Warning: this function will only aggregate the values that can be combined.
 * Most of the metadata will be taken from the first object in the array.
 */
export function aggregateKeywordRanks(ranks: RubyKeywordRankReport[]): RubyKeywordRankReport {
    const totalMetrics = emptyRankReport();

    if (ranks.length < 1) {
        return totalMetrics;
    }

    // We can't aggregate these values, so just copy the first report
    totalMetrics.reportRef = ranks[0].reportRef;
    totalMetrics.searchTerm = ranks[0].searchTerm;
    totalMetrics.teamId = ranks[0].teamId;
    totalMetrics.orgRef = ranks[0].orgRef;
    totalMetrics.adamRef = ranks[0].adamRef;
    totalMetrics.appName = ranks[0].appName;
    totalMetrics.country = ranks[0].country;

    // We can aggregate these values
    totalMetrics.period = mapMin(ranks, (rank) => rank.period);
    totalMetrics.lowImpressionShare = mapMin(ranks, (rank) => rank.lowImpressionShare);
    totalMetrics.highImpressionShare = mapMax(ranks, (rank) => rank.highImpressionShare);
    totalMetrics.searchPopularity = mapAverage(ranks, (rank) => rank.searchPopularity);
    totalMetrics.rank = getKeywordValueAsRank(mapAverage(ranks, (rank) => getKeywordRankAsValue(rank.rank)));

    return totalMetrics;
}

type TemporalKeywordRankReport = RubyKeywordRankReport & Temporal;

export function aggregateKeywordRanksAtGranularity(
    ranks: RubyKeywordRankReport[],
    granularity: ReportGranularity,
    from: number,
    to: number
): TemporalKeywordRankReport[] {
    // We need to head off hourly and handle it as aggregateAtGranularity cannot disaggregate from daily data
    if (granularity === ReportGranularity.HOURLY) {
        from = tsToStartOfGranularity(from, granularity);
        to = tsToStartOfGranularity(to, granularity);

        const reportGroups = generateEmptyTemporalReport(from, to, granularity, emptyRankReport());

        const hourlyReports = reportGroups.map<TemporalKeywordRankReport>((emptyReport) => {
            const dayStart = moment.utc(emptyReport.date).startOf("day");
            const dayReport = ranks.find((rank) => moment(rank.period).isSame(dayStart));
            return {
                ...emptyReport,
                ...dayReport,
            };
        });

        return hourlyReports;
    }

    // Convert to match Temporal typing
    const temporalRanks = ranks.map<TemporalKeywordRankReport>((rank) => {
        return {
            ...rank,
            date: rank.period,
            granularity: ReportGranularity.DAILY,
        };
    });

    return aggregateAtGranularity<TemporalKeywordRankReport>(
        temporalRanks,
        granularity,
        emptyRankReport(),
        aggregateKeywordRanks,
        from,
        to
    );
}
