import { rubyService } from "@/services";
import difference from "lodash/difference";
import intersection from "lodash/intersection";
import { put } from "redux-saga/effects";
import { type ExtractRequestActionRequestType } from "../actions/Action";
import { ActionName } from "../actions/ActionType";
import { channelConfigActions, regionConfigActions } from "../actions/configActions";
import {
    negativeKeywordsActions,
    seedKeywordsActions,
    targetingKeywordsActions,
    type KeywordsActions,
} from "../actions/keywordsActions";
import { selectCampaign, selectChannel, selectRegions } from "../selectors/campaignSelectors";
import {
    hasKeywordDiscovery,
    selectNegativeKeywords,
    selectSeedKeywords,
    selectTargetingKeywords,
} from "../selectors/keywordsSelectors";
import {
    RubyKeywordMatchType,
    RubyKeywordStatus,
    RubyPurpose,
    RubyRegionStatus,
    type RubyASRRegion,
    type RubyCampaignId,
    type RubyCountry,
    type RubyKeyword,
    type RubyKeywordAssignment,
} from "../services/backend/RubyData";
import {
    runDataRequestAction,
    runRequestAction,
    runRequestSaga,
    selectAsyncDataFromState,
    selectFromState,
    takeRequests,
} from "../utilities/saga";
import { isChannelWithPurposes, isRegionWithPurpose } from "../utilities/types";
import { updateConfigSaga, updateRegionsSaga } from "./configSaga";

export function* loadTargetingKeywordsSaga(
    action: ExtractRequestActionRequestType<KeywordsActions.GetTargetingKeywordsAction>
) {
    yield* runDataRequestAction(
        action,
        targetingKeywordsActions.list,
        (state, req) => selectTargetingKeywords(state, req.campaignId),
        (req) =>
            rubyService.appleChannels
                .listAsrChannelTargetingKeywords(req, { assignments: true }, { all: true })
                .then((res) => res.results)
    );
}

function* loadNegativeKeywordsSaga(action: ExtractRequestActionRequestType<KeywordsActions.GetNegativeKeywordsAction>) {
    yield* runDataRequestAction(
        action,
        negativeKeywordsActions.list,
        (state, req) => selectNegativeKeywords(state, req.campaignId),
        async (req) =>
            rubyService.appleChannels
                .listAsrChannelNegativeKeywords(req, undefined, { all: true })
                .then((res) => res.results)
    );
}

function* loadSeedKeywordsSaga(action: ExtractRequestActionRequestType<KeywordsActions.GetSeedKeywordsAction>) {
    yield* runDataRequestAction(
        action,
        seedKeywordsActions.list,
        (state, req) => selectSeedKeywords(state, req.campaignId),
        async (req) =>
            rubyService.appleChannels
                .listAsrChannelSeedKeywords(req, undefined, { all: true })
                .then((res) => res.results)
    );
}

function* loadTargetingKeywordBidsInfoSaga(
    action: ExtractRequestActionRequestType<KeywordsActions.GetTargetingKeywordBidsInfoAction>
) {
    yield* runDataRequestAction(
        action,
        targetingKeywordsActions.listBidsInfo,
        (state, req) => state.keywords.bidsInfo[req.campaignId],
        (req) =>
            rubyService.appleReplica
                .getAdGroupTargetingKeywordBidsInfo(
                    { teamId: req.teamId },
                    { campaignId: req.campaignId, installs: true },
                    { all: true }
                )
                .then((res) => res.results)
    );
}

function* getAddKeywordRegionsMap(campaignId: RubyCampaignId) {
    const regions = yield* selectFromState(
        (state) => (state.campaigns.regions[campaignId]?.data as RubyASRRegion[]) ?? []
    );
    const regionMap = new Map<RubyCountry, RubyASRRegion["id"]>();
    regions.forEach((region) => {
        if (region.purpose === RubyPurpose.BID_DISCOVERY) {
            regionMap.set(region.country, region.id);
        } else if (region.purpose === RubyPurpose.BID_OPTIMISATION && !regionMap.has(region.country)) {
            regionMap.set(region.country, region.id);
        }
        // TODO - check this with Sam, if it can't find a disco region, the keyword is added directly to optimise
    });
    return regionMap;
}

export function* addTargetingKeywordsSaga(
    action: ExtractRequestActionRequestType<KeywordsActions.AddTargetingKeywordsAction>
) {
    yield put(
        targetingKeywordsActions.list.request({
            campaignId: action.payload.request.campaignId,
            teamId: action.payload.request.teamId,
        })
    );
    const regionMap = yield* getAddKeywordRegionsMap(action.payload.request.campaignId);
    const existingKeywords = yield* selectAsyncDataFromState((state) =>
        selectTargetingKeywords(state, action.payload.request.campaignId)
    );

    yield* runRequestAction(action, targetingKeywordsActions.add, async (req) => {
        const keywords = await Promise.all(
            req.keywords.map((kw) =>
                rubyService.appleChannels.createAsrChannelTargetingKeyword(
                    {
                        campaignId: req.campaignId,
                        teamId: req.teamId,
                    },
                    {
                        status: RubyKeywordStatus.ACTIVE,
                        text: kw.trim().toLocaleLowerCase(),
                        tag: req.tag || undefined,
                        match: req.match ?? RubyKeywordMatchType.EXACT,
                    }
                )
            )
        );

        const assignments = await rubyService.appleChannels
            .updateAsrChannelKeywordAssignments(
                {
                    campaignId: req.campaignId,
                    teamId: req.teamId,
                },
                {
                    assignments: keywords.flatMap((keyword) =>
                        req.regions.map((region) => ({
                            keywordId: keyword.id,
                            status: RubyKeywordStatus.ACTIVE,
                            regionId: regionMap.get(region),
                        }))
                    ),
                }
            )
            .then((res) => res.results);

        for (const keyword of keywords) {
            const existingKeyword = existingKeywords.find((kw) => kw.id === keyword.id);
            if (existingKeyword) {
                keyword.assignments = existingKeyword.assignments ?? [];
            }
        }

        for (const ass of assignments) {
            const keyword = keywords.find((kw) => kw.id === ass.keywordId);
            keyword.assignments ??= [];
            keyword.assignments = keyword.assignments.filter((existingAss) => existingAss.regionId !== ass.regionId);
            keyword.assignments.push(ass);
        }

        return keywords;
    });
}

export function* addNegativeKeywordsSaga(
    action: ExtractRequestActionRequestType<KeywordsActions.AddNegativeKeywordsAction>
) {
    yield* runRequestSaga(action, negativeKeywordsActions.add, function* (req) {
        const keywordsToNegative = req.keywords.map((kw) => kw.trim().toLocaleLowerCase());

        yield put(
            targetingKeywordsActions.list.request({
                campaignId: action.payload.request.campaignId,
                teamId: action.payload.request.teamId,
            })
        );
        const targetingKeywords = yield* selectFromState((state) => selectTargetingKeywords(state, req.campaignId));
        const targetingKeywordsToRemove = targetingKeywords?.data?.filter((kw) =>
            keywordsToNegative.includes(kw.text.trim().toLocaleLowerCase())
        );
        yield Promise.all(
            targetingKeywordsToRemove.map((keyword) =>
                rubyService.appleChannels.deleteAsrChannelTargetingKeyword({
                    campaignId: req.campaignId,
                    teamId: req.teamId,
                    keywordId: keyword.id,
                })
            )
        );

        yield put(
            negativeKeywordsActions.list.request({
                campaignId: action.payload.request.campaignId,
                teamId: action.payload.request.teamId,
            })
        );
        const negativeKeywords = yield* selectFromState((state) => selectNegativeKeywords(state, req.campaignId));
        const negativeKeywordsToAdd = keywordsToNegative.filter(
            (kw) => !negativeKeywords?.data?.find((k) => k.text.trim().toLocaleLowerCase() === kw)
        );
        const addedNegativeKeywords = yield Promise.all(
            negativeKeywordsToAdd.map((kw) =>
                rubyService.appleChannels.createAsrChannelNegativeKeyword(
                    {
                        campaignId: req.campaignId,
                        teamId: req.teamId,
                    },
                    {
                        status: RubyKeywordStatus.ACTIVE,
                        text: kw.trim().toLocaleLowerCase(),
                        // tag: req.tag || undefined, // TODO - this needs to be added to the API
                        match: RubyKeywordMatchType.EXACT, // TODO this shouldn't be here
                    }
                )
            )
        );

        return {
            addedNegativeKeywords: addedNegativeKeywords as RubyKeyword[], // Had to cast this, TS doesn't like the yielded promise
            removedTargetingKeywords: targetingKeywordsToRemove,
        };
    });
}

export function* removeTargetingKeywordsSaga(
    action: ExtractRequestActionRequestType<KeywordsActions.RemoveTargetingKeywordsAction>
) {
    yield* runRequestAction(action, targetingKeywordsActions.remove, async (req) => {
        await Promise.all(
            req.keywords.map(async (keyword) => {
                await rubyService.appleChannels.deleteAsrChannelTargetingKeyword({
                    campaignId: req.campaignId,
                    teamId: req.teamId,
                    keywordId: keyword.id,
                });
            })
        );
    });
}

export function* makeNegativeTargetingKeywordsSaga(
    action: ExtractRequestActionRequestType<KeywordsActions.MakeNegativeTargetingKeywordsAction>
) {
    yield* runRequestAction(action, targetingKeywordsActions.makeNegative, async (req) => {
        return await Promise.all(
            req.keywords.map(async (keyword) => {
                await rubyService.appleChannels.deleteAsrChannelTargetingKeyword({
                    campaignId: req.campaignId,
                    teamId: req.teamId,
                    keywordId: keyword.id,
                });
                return await rubyService.appleChannels.createAsrChannelNegativeKeyword(
                    {
                        campaignId: req.campaignId,
                        teamId: req.teamId,
                    },
                    {
                        status: RubyKeywordStatus.ACTIVE,
                        text: keyword.text.toLocaleLowerCase(),
                        // tag: keyword.tag || undefined, // TODO - this needs to be added to the API
                        match: RubyKeywordMatchType.EXACT, // TODO this shouldn't be here
                    }
                );
            })
        );
    });
}

export function* removeNegativeKeywordsSaga(
    action: ExtractRequestActionRequestType<KeywordsActions.RemoveNegativeKeywordsAction>
) {
    yield* runRequestAction(action, negativeKeywordsActions.remove, async (req) => {
        await Promise.all(
            action.payload.request.keywords.map((keyword) =>
                rubyService.appleChannels.deleteAsrChannelNegativeKeyword({
                    campaignId: req.campaignId,
                    teamId: req.teamId,
                    keywordId: keyword.id,
                })
            )
        );
    });
}

function* updateSeedKeywordsSaga(action: ExtractRequestActionRequestType<KeywordsActions.UpdateSeedKeywordsAction>) {
    yield* runRequestSaga(action, seedKeywordsActions.update, function* (req) {
        const campaign = yield* selectAsyncDataFromState((state) => selectCampaign(state, req.campaignId));
        const channel = yield* selectAsyncDataFromState((state) => selectChannel(state, req.campaignId));
        if (!isChannelWithPurposes(campaign.channelType, channel)) {
            throw new Error("Incorrect channel type, seed keywords are not supported");
        }

        const [newSeedKeywords] = (yield Promise.all([
            Promise.all(
                req.newKeywords.map((text) =>
                    rubyService.appleChannels.createAsrChannelSeedKeyword(
                        {
                            campaignId: req.campaignId,
                            teamId: req.teamId,
                        },
                        {
                            status: RubyKeywordStatus.ACTIVE,
                            text: text.trim().toLocaleLowerCase(),
                            match: RubyKeywordMatchType.EXACT, // TODO this shouldn't be here
                        }
                    )
                )
            ),
            ...req.removedKeywords.map((keyword) =>
                rubyService.appleChannels.deleteAsrChannelSeedKeyword({
                    campaignId: req.campaignId,
                    teamId: req.teamId,
                    keywordId: keyword.id,
                })
            ),
        ])) as [RubyKeyword[]];

        let statusChange: RubyRegionStatus = null;

        if (!req.enabled && hasKeywordDiscovery(campaign?.channelType, channel)) {
            // Turn off KD
            yield updateConfigSaga(
                channelConfigActions.update.request({
                    campaignId: req.campaignId,
                    teamId: req.teamId,
                    channelType: campaign?.channelType,
                    update: {
                        purposes: channel.purposes.filter((p) => p !== RubyPurpose.KEYWORD_DISCOVERY),
                    },
                })
            );
            statusChange = RubyRegionStatus.DISABLED;
        }
        if (req.enabled && !hasKeywordDiscovery(campaign?.channelType, channel)) {
            // Turn on KD
            yield updateConfigSaga(
                channelConfigActions.update.request({
                    campaignId: req.campaignId,
                    teamId: req.teamId,
                    channelType: campaign?.channelType,
                    update: {
                        purposes: [...channel.purposes, RubyPurpose.KEYWORD_DISCOVERY],
                    },
                })
            );
            statusChange = RubyRegionStatus.ACTIVE;
        }

        if (statusChange) {
            const allRegions = yield* selectAsyncDataFromState((state) => selectRegions(state, req.campaignId));
            const regions = allRegions.filter(
                (r) => isRegionWithPurpose(campaign.channelType, r) && r.purpose === RubyPurpose.KEYWORD_DISCOVERY
            );
            yield updateRegionsSaga(
                regionConfigActions.update.request({
                    campaignId: req.campaignId,
                    teamId: req.teamId,
                    channelType: campaign?.channelType,
                    update: regions.map((r) => ({
                        id: r.id,
                        status: statusChange,
                    })),
                })
            );
        }

        return newSeedKeywords;
    });
}

function* tagTargetingKeywordsSaga(
    action: ExtractRequestActionRequestType<KeywordsActions.TagTargetingKeywordsAction>
) {
    yield* runRequestAction(action, targetingKeywordsActions.tag, (req) => {
        return Promise.all(
            req.keywords.map((keyword) =>
                rubyService.appleChannels.updateAsrChannelTargetingKeyword(
                    {
                        campaignId: req.campaignId,
                        teamId: req.teamId,
                        keywordId: keyword.id,
                    },
                    {
                        tag: req.tag || undefined,
                        status: keyword.status,
                    }
                )
            )
        );
    });
}

function* tagNegativeKeywordsSaga(action: ExtractRequestActionRequestType<KeywordsActions.TagNegativeKeywordsAction>) {
    yield* runRequestAction(action, negativeKeywordsActions.tag, (req) => {
        return Promise.all(
            req.keywords.map((keyword) =>
                rubyService.appleChannels.updateAsrChannelNegativeKeyword(
                    {
                        campaignId: req.campaignId,
                        teamId: req.teamId,
                        keywordId: keyword.id,
                    },
                    {
                        // tag: req.tag || undefined, // TODO - this needs to be added to the API
                        status: keyword.status,
                    }
                )
            )
        );
    });
}

function* setTargetingRegionsSaga(action: ExtractRequestActionRequestType<KeywordsActions.SetTargetingRegionsAction>) {
    const regionMap = yield* getAddKeywordRegionsMap(action.payload.request.campaignId);
    yield* runRequestAction(action, targetingKeywordsActions.setRegions, (req) => {
        const assignments: RubyKeywordAssignment[] = [];
        req.keywords.forEach((keyword) => {
            // Remove missing regions
            const missingRegions = difference(keyword.countries, req.regions);
            missingRegions.forEach((countryCode) => {
                assignments.push({
                    ...keyword.assignmentMap[countryCode],
                    status: RubyKeywordStatus.DISABLED,
                });
            });

            //Keep unchanged regions
            const maintainedRegions = intersection(keyword.countries, req.regions);
            maintainedRegions.forEach((countryCode) => {
                assignments.push({
                    ...keyword.assignmentMap[countryCode],
                });
            });

            //Add new regions
            const newRegions = difference(req.regions, keyword.countries);
            newRegions.forEach((countryCode) => {
                assignments.push({
                    status: RubyKeywordStatus.ACTIVE,
                    keywordId: keyword.id,
                    regionId: regionMap.get(countryCode),
                });
            });
        });

        return rubyService.appleChannels
            .updateAsrChannelKeywordAssignments(
                {
                    campaignId: req.campaignId,
                    teamId: req.teamId,
                },
                {
                    assignments,
                }
            )
            .then((res) => res.results);
    });
}

function* setTargetingBidsSaga(action: ExtractRequestActionRequestType<KeywordsActions.SetTargetingBidsAction>) {
    yield* runRequestAction(action, targetingKeywordsActions.setBids, (req) => {
        const assignments: RubyKeywordAssignment[] = [];

        const bidMap = req.bids;

        req.keywords.forEach((keyword) => {
            Object.keys(bidMap).forEach((countryCode: RubyCountry) => {
                if (keyword.assignmentMap[countryCode]) {
                    assignments.push({
                        ...keyword.assignmentMap[countryCode],
                        forceBidAmount: bidMap[countryCode] > 0 ? bidMap[countryCode] : null,
                    });
                }
            });
        });

        return rubyService.appleChannels
            .updateAsrChannelKeywordAssignments(
                {
                    campaignId: req.campaignId,
                    teamId: req.teamId,
                },
                {
                    assignments,
                }
            )
            .then((res) => res.results);
    });
}

function* setTargetingMatchSaga(action: ExtractRequestActionRequestType<KeywordsActions.SetTargetingMatchAction>) {
    yield* runRequestAction(action, targetingKeywordsActions.setMatch, (req) => {
        return Promise.all(
            req.keywords.map(async (keyword) => {
                if (keyword.match === req.match) {
                    return keyword;
                }
                const newKeyword = await rubyService.appleChannels.createAsrChannelTargetingKeyword(
                    {
                        campaignId: req.campaignId,
                        teamId: req.teamId,
                    },
                    {
                        status: keyword.status,
                        text: keyword.text,
                        tag: keyword.tag,
                        match: req.match,
                    }
                );

                await rubyService.appleChannels.updateAsrChannelKeywordAssignments(
                    {
                        campaignId: req.campaignId,
                        teamId: req.teamId,
                    },
                    {
                        assignments: keyword.assignments.map((kw) => ({
                            ...kw,
                            keywordId: newKeyword.id,
                            apple: undefined,
                        })),
                    }
                );

                await rubyService.appleChannels.deleteAsrChannelTargetingKeyword({
                    campaignId: req.campaignId,
                    teamId: req.teamId,
                    keywordId: keyword.id,
                });

                return await rubyService.appleChannels.getAsrChannelTargetingKeyword(
                    {
                        campaignId: req.campaignId,
                        teamId: req.teamId,
                        keywordId: newKeyword.id,
                    },
                    {
                        assignments: true,
                    }
                );
            })
        );
    });
}

function* importTargetingKeywordsSaga(
    action: ExtractRequestActionRequestType<KeywordsActions.ImportTargetingKeywordsAction>
) {
    const regionMap = yield* getAddKeywordRegionsMap(action.payload.request.campaignId);
    yield* runRequestAction(action, targetingKeywordsActions.import, async (req) => {
        const keywords = await Promise.all(
            req.keywords.map((kw) =>
                rubyService.appleChannels
                    .createAsrChannelTargetingKeyword(
                        {
                            campaignId: req.campaignId,
                            teamId: req.teamId,
                        },
                        {
                            status: RubyKeywordStatus.ACTIVE,
                            text: kw.text.trim().toLocaleLowerCase(),
                            tag: kw.tag || undefined,
                            match: kw.match ?? RubyKeywordMatchType.EXACT,
                        }
                    )
                    .then((resp) => ({
                        keywordData: resp,
                        importData: kw,
                    }))
            )
        );

        return await Promise.all(
            keywords.map(async (keyword) => {
                const assignments = keyword.importData.countries.map((country) => ({
                    keywordId: keyword.keywordData.id,
                    status: RubyKeywordStatus.ACTIVE,
                    regionId: regionMap.get(country),
                }));
                const assignmentResp = await rubyService.appleChannels.updateAsrChannelKeywordAssignments(
                    {
                        campaignId: req.campaignId,
                        teamId: req.teamId,
                    },
                    { assignments }
                );
                return {
                    ...keyword.keywordData,
                    assignments: assignmentResp.results,
                };
            })
        );
    });
}

function* importNegativeKeywordsSaga(
    action: ExtractRequestActionRequestType<KeywordsActions.ImportTargetingKeywordsAction>
) {
    yield* runRequestAction(action, negativeKeywordsActions.import, async (req) => {
        return await Promise.all(
            req.keywords.map((kw) =>
                rubyService.appleChannels.createAsrChannelNegativeKeyword(
                    {
                        campaignId: req.campaignId,
                        teamId: req.teamId,
                    },
                    {
                        status: RubyKeywordStatus.ACTIVE,
                        text: kw.text.trim().toLocaleLowerCase(),
                        // tag: kw.tag || undefined, // TODO - this needs to be added to the API
                        match: RubyKeywordMatchType.EXACT, // TODO this shouldn't be here
                    }
                )
            )
        );
    });
}

export default function* keywordsSaga() {
    yield* takeRequests(ActionName.GET_TARGETING_KEYWORDS, loadTargetingKeywordsSaga);
    yield* takeRequests(ActionName.GET_NEGATIVE_KEYWORDS, loadNegativeKeywordsSaga);
    yield* takeRequests(ActionName.GET_SEED_KEYWORDS, loadSeedKeywordsSaga);
    yield* takeRequests(ActionName.GET_TARGETING_KEYWORD_BIDS_INFO, loadTargetingKeywordBidsInfoSaga);

    yield* takeRequests(ActionName.ADD_TARGETING_KEYWORDS, addTargetingKeywordsSaga);
    yield* takeRequests(ActionName.ADD_NEGATIVE_KEYWORDS, addNegativeKeywordsSaga);

    yield* takeRequests(ActionName.REMOVE_TARGETING_KEYWORDS, removeTargetingKeywordsSaga);
    yield* takeRequests(ActionName.REMOVE_NEGATIVE_KEYWORDS, removeNegativeKeywordsSaga);
    yield* takeRequests(ActionName.MAKE_NEGATIVE_TARGETING_KEYWORDS, makeNegativeTargetingKeywordsSaga);

    yield* takeRequests(ActionName.UPDATE_SEED_KEYWORDS, updateSeedKeywordsSaga);

    yield* takeRequests(ActionName.TAG_TARGETING_KEYWORDS, tagTargetingKeywordsSaga);
    yield* takeRequests(ActionName.TAG_NEGATIVE_KEYWORDS, tagNegativeKeywordsSaga);

    yield* takeRequests(ActionName.SET_TARGETING_REGIONS, setTargetingRegionsSaga);
    yield* takeRequests(ActionName.SET_TARGETING_BIDS, setTargetingBidsSaga);
    yield* takeRequests(ActionName.SET_TARGETING_MATCH, setTargetingMatchSaga);

    yield* takeRequests(ActionName.IMPORT_TARGETING_KEYWORDS, importTargetingKeywordsSaga);
    yield* takeRequests(ActionName.IMPORT_NEGATIVE_KEYWORDS, importNegativeKeywordsSaga);
}
