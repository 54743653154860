import { rubyService } from "@/services";
import { ChannelType } from "@redbox-ruby/data-lib";
import clamp from "lodash/clamp";
import { put, takeEvery } from "redux-saga/effects";
import { type ExtractRequestActionRequestType } from "../actions/Action";
import { ActionName } from "../actions/ActionType";
import {
    budgetConfigActions,
    calendarActions,
    channelConfigActions,
    regionConfigActions,
    type ConfigActions,
    type UpdateRegionWeightingsActionPayload,
} from "../actions/configActions";
import { getRegionsReportAction } from "../actions/reportingActions";
import { selectCampaignActiveBudget, selectRegions } from "../selectors/campaignSelectors";
import { selectCampaignRegionConfig } from "../selectors/configSelectors";
import {
    RubyBudgetPlanStatus,
    RubyChannelType,
    RubyPurpose,
    RubyRegionStatus,
    type RubyRegion,
    type RubyRegionId,
    type RubyUpdateRegionResponse,
} from "../services/backend/RubyData";
import { type CountryMap } from "../types/utils";
import { DEFAULT_WEEKLY_OVERSPEND, isActiveBudgetPlan } from "../utilities/budget";
import {
    runDataRequestAction,
    runRequestAction,
    selectAsyncDataFromState,
    selectFromState,
    takeRequests,
} from "../utilities/saga";
import { delay } from "../utilities/time";
import { isRegionWithPurpose } from "../utilities/types";

export function* updateConfigSaga(action: ExtractRequestActionRequestType<ConfigActions.UpdateChannelAction>) {
    yield* runRequestAction(action, channelConfigActions.update, ({ teamId, campaignId, channelType, update }) => {
        const ids = {
            teamId,
            campaignId,
        };
        switch (channelType) {
            case RubyChannelType.APPLE_SEARCH_RESULTS: {
                return rubyService.appleChannels.updateAsrChannel(ids, update);
            }
            case RubyChannelType.APPLE_SEARCH_TABS: {
                return rubyService.appleChannels.updateAstChannel(ids, update);
            }
            case RubyChannelType.APPLE_TODAY_TABS: {
                return rubyService.appleChannels.updateAttChannel(ids, update);
            }
            case RubyChannelType.PRODUCT_PAGE_BROWSE: {
                return rubyService.appleChannels.updatePpbChannel(ids, update);
            }
        }
    });
}

export function calculateRegionWeightings(
    channelType: RubyChannelType,
    regions: RubyRegion[],
    countryWeights: CountryMap<number>,
    discoveryWeight: number // 0 - 1
): Record<RubyRegionId, number> {
    const finalWeights: Record<RubyRegionId, number> = {};
    discoveryWeight = clamp(discoveryWeight, 0.02, 1);

    regions.forEach((region) => {
        const purpose = isRegionWithPurpose(channelType, region) ? region.purpose : null;

        let weight = countryWeights[region.country];

        if (purpose === RubyPurpose.KEYWORD_DISCOVERY) {
            weight *= 0.01;
        } else if (purpose === RubyPurpose.BID_DISCOVERY) {
            weight *= discoveryWeight - 0.01;
        } else if (purpose === RubyPurpose.BID_OPTIMISATION) {
            weight *= 1 - discoveryWeight;
        }

        finalWeights[region.id] = weight;
    });

    return finalWeights;
}

function* addCalendarSaga(action: ExtractRequestActionRequestType<ConfigActions.AddCalendarAction>) {
    yield* runRequestAction(action, calendarActions.add, async (req) => {
        const response = await rubyService.services.addCalendarAndEvents({
            data: req.data,
            calendar: { name: req.name },
        });
        return response;
    });
}

function* listCalendarsSaga(action: ExtractRequestActionRequestType<ConfigActions.ListCalendarsAction>) {
    yield* runRequestAction(action, calendarActions.list, () =>
        rubyService.services.listCalendars().then((res) => res.calendars)
    );
}

function* deleteCalendarSaga(action: ExtractRequestActionRequestType<ConfigActions.DeleteCalendarAction>) {
    yield* runRequestAction(action, calendarActions.delete, async (req) => {
        await rubyService.services.deleteCalendar({ calendarId: req });
    });
}

export function updateWeightings({
    teamId,
    campaignId,
    channelType,
    regions,
    weightings,
    discoveryWeighting,
}: UpdateRegionWeightingsActionPayload): Promise<RubyUpdateRegionResponse[]> {
    const weights = calculateRegionWeightings(channelType, regions, weightings, discoveryWeighting);
    return Promise.all(
        regions.map((region) => {
            const id = {
                teamId,
                campaignId,
                regionId: region.id,
            };
            const weight = {
                budgetWeighting: weights[region.id],
            };
            switch (channelType) {
                case RubyChannelType.APPLE_SEARCH_RESULTS:
                    return rubyService.appleChannels.updateAsrChannelRegion(id, weight);
                case RubyChannelType.APPLE_SEARCH_TABS:
                    return rubyService.appleChannels.updateAstChannelRegion(id, weight);
                case RubyChannelType.APPLE_TODAY_TABS:
                    return rubyService.appleChannels.updateAttChannelRegion(id, weight);
                case RubyChannelType.PRODUCT_PAGE_BROWSE:
                    return rubyService.appleChannels.updatePpbChannelRegion(id, weight);
                default:
                    return Promise.reject();
            }
        })
    );
}

function* createRegionsSaga(action: ExtractRequestActionRequestType<ConfigActions.CreateRegionsAction>) {
    // This is a bit of a cheat to fire off the channel update first
    // Would technically be better to wait for this to complete as part of the saga
    if (action.payload.request.channelTargeting) {
        yield put(
            channelConfigActions.update.request({
                campaignId: action.payload.request.campaignId,
                teamId: action.payload.request.teamId,
                channelType: action.payload.request.channelType,
                update: {
                    targetingDimensions: action.payload.request.channelTargeting,
                },
            })
        );
    }

    yield* runRequestAction(
        action,
        regionConfigActions.create,
        async ({ teamId, campaignId, channelType, regions, create, config, weightings, discoveryWeighting }) => {
            const newRegions = await Promise.all(
                create.map(async (country) => {
                    const id = {
                        teamId,
                        campaignId,
                    };
                    const payload = {
                        ...config,
                        status: RubyRegionStatus.ACTIVE,
                        country,
                        budgetWeighting: 0, //Must be updated later per region
                    };
                    const resp = await (() => {
                        switch (channelType) {
                            case RubyChannelType.APPLE_SEARCH_RESULTS:
                                return rubyService.appleChannels.createAsrChannelRegion(id, payload);
                            case RubyChannelType.APPLE_SEARCH_TABS:
                                return rubyService.appleChannels.createAstChannelRegion(id, payload);
                            case RubyChannelType.APPLE_TODAY_TABS:
                                return rubyService.appleChannels.createAttChannelRegion(id, payload);
                            case RubyChannelType.PRODUCT_PAGE_BROWSE:
                                return rubyService.appleChannels.createPpbChannelRegion(id, payload);
                        }
                    })();
                    return resp.results;
                })
            );

            return await updateWeightings({
                teamId,
                campaignId,
                channelType,
                regions: [...Object.values(regions).flat(), ...newRegions.flat()],
                weightings,
                discoveryWeighting,
            });
        }
    );
}

export function* updateRegionsSaga(action: ExtractRequestActionRequestType<ConfigActions.UpdateRegionsAction>) {
    yield* runRequestAction(action, regionConfigActions.update, ({ teamId, campaignId, channelType, update }) => {
        return Promise.all(
            update.map(({ id, ...region }) => {
                const payloadId = {
                    teamId,
                    campaignId,
                    regionId: id,
                };
                switch (channelType) {
                    case RubyChannelType.APPLE_SEARCH_RESULTS:
                        return rubyService.appleChannels.updateAsrChannelRegion(payloadId, region);
                    case RubyChannelType.APPLE_SEARCH_TABS:
                        return rubyService.appleChannels.updateAstChannelRegion(payloadId, region);
                    case RubyChannelType.APPLE_TODAY_TABS:
                        return rubyService.appleChannels.updateAttChannelRegion(payloadId, region);
                    case RubyChannelType.PRODUCT_PAGE_BROWSE:
                        return rubyService.appleChannels.updatePpbChannelRegion(payloadId, region);
                    default:
                        return Promise.reject();
                }
            })
        );
    });
}

export function* updateDiscoveryWeightingSaga(action: ConfigActions.UpdateDiscoveryWeightingAction) {
    const budget = yield* selectFromState((state) => selectCampaignActiveBudget(state, action.payload.campaignId));
    yield put(
        getRegionsReportAction.request({
            campaignId: action.payload.campaignId,
            teamId: action.payload.teamId,
            from: budget.data?.start,
            to: budget.data?.end,
        })
    );
    yield delay(100);

    const regions = yield* selectFromState((state) => selectRegions(state, action.payload.campaignId).data);
    const regionsConfig = yield* selectAsyncDataFromState((state) =>
        selectCampaignRegionConfig(state, action.payload.campaignId)
    );

    yield* runRequestAction(
        regionConfigActions.updateWeightings.request({
            ...action.payload,
            regions: regions,
            weightings: regionsConfig.weightings,
        }),
        regionConfigActions.updateWeightings,
        updateWeightings
    );
}

function* updateRegionWeightingsSaga(
    action: ExtractRequestActionRequestType<ConfigActions.UpdateRegionWeightingsAction>
) {
    yield* runRequestAction(action, regionConfigActions.updateWeightings, updateWeightings);
}

function* addBudgetPlanSaga(action: ExtractRequestActionRequestType<ConfigActions.AddBudgetPlanAction>) {
    yield* runRequestAction(action, budgetConfigActions.add, (req) => {
        return rubyService.budgets.createBudgetPlan(
            { campaignId: req.campaignId },
            {
                totalBudget: req.totalAmount,
                details: {
                    weekdayOverspend: {
                        ...DEFAULT_WEEKLY_OVERSPEND,
                        ...(req.weeklyOverspend ?? undefined),
                    },
                },
                policy: req.policy,
                method: req.method,
                status: RubyBudgetPlanStatus.ACTIVE,
                start: req.timeRange[0],
                end: req.timeRange[1],
            }
        );
    });
}

function* removeBudgetPlanSaga(action: ExtractRequestActionRequestType<ConfigActions.RemoveBudgetPlanAction>) {
    yield* runRequestAction(action, budgetConfigActions.delete, async (req) => {
        await rubyService.budgets.deleteBudgetPlan({ campaignId: req.campaignId, planId: req.id });
    });
}

export function* updateBudgetPlanSaga(action: ExtractRequestActionRequestType<ConfigActions.UpdateBudgetPlanAction>) {
    yield* runRequestAction(action, budgetConfigActions.update, async (req) => {
        const budgetPlan = await rubyService.budgets.updateBudgetPlan(
            { campaignId: req.campaignId, planId: req.id },
            {
                totalBudget: req.totalAmount,
                details: {
                    weekdayOverspend: {
                        ...DEFAULT_WEEKLY_OVERSPEND,
                        ...(req.weeklyOverspend ?? undefined),
                    },
                },
                policy: req.policy,
                method: req.method,
                status: RubyBudgetPlanStatus.ACTIVE,
                start: req.timeRange[0],
                end: req.timeRange[1],
            }
        );

        if (isActiveBudgetPlan({ ...budgetPlan, allocations: [] })) {
            await rubyService.budgets.runBudgetManager({
                campaignId: req.campaignId,
            });
        }

        return budgetPlan;
    });
}
function* updateChannelNamesSaga(action: ExtractRequestActionRequestType<ConfigActions.UpdateChannelNamesAction>) {
    yield* runRequestAction(action, channelConfigActions.updateCampaignNames, (req) => {
        const ids = {
            teamId: req.teamId,
            campaignId: req.campaignId,
        };
        switch (req.channelType) {
            case ChannelType.APPLE_SEARCH_RESULTS:
                return rubyService.appleChannels
                    .updateAsrChannelCampaignNames(ids, req.update)
                    .then((res) => res.results);
            case ChannelType.APPLE_SEARCH_TABS:
                return rubyService.appleChannels
                    .updateAstChannelCampaignNames(ids, req.update)
                    .then((res) => res.results);
            case ChannelType.APPLE_TODAY_TABS:
                return rubyService.appleChannels
                    .updateAttChannelCampaignNames(ids, req.update)
                    .then((res) => res.results);
            case ChannelType.PRODUCT_PAGE_BROWSE:
                return rubyService.appleChannels
                    .updatePpbChannelCampaignNames(ids, req.update)
                    .then((res) => res.results);
            default:
                return Promise.reject();
        }
    });
}

function* getChannelNamesSaga(action: ExtractRequestActionRequestType<ConfigActions.GetChannelNamesAction>) {
    yield* runDataRequestAction(
        action,
        channelConfigActions.getChannelNamesAction,
        (state, req) => state.config.channelNames[req.campaignId],
        async (req) => {
            const ids = { teamId: req.teamId, campaignId: req.campaignId };
            switch (req.channelType) {
                case RubyChannelType.APPLE_SEARCH_RESULTS: {
                    return rubyService.appleChannels.listAsrChannelCampaignNames(ids, {}).then((res) => res.results);
                }
                case RubyChannelType.APPLE_SEARCH_TABS: {
                    return rubyService.appleChannels.listAstChannelCampaignNames(ids, {}).then((res) => res.results);
                }
                case RubyChannelType.APPLE_TODAY_TABS: {
                    return rubyService.appleChannels.listAttChannelCampaignNames(ids, {}).then((res) => res.results);
                }
                case RubyChannelType.PRODUCT_PAGE_BROWSE: {
                    return rubyService.appleChannels.listPpbChannelCampaignNames(ids, {}).then((res) => res.results);
                }
                default:
                    return Promise.reject();
            }
        }
    );
}

function* setAllocationsSaga(action: ExtractRequestActionRequestType<ConfigActions.SetAllocationsAction>) {
    yield* runRequestAction(action, budgetConfigActions.setAllocations, async (req) => {
        await rubyService.budgets.forceBudgetAllocation(
            { campaignId: req.campaignId },
            {
                details: {
                    regions: req.regions.map((r) => ({
                        ...r,
                        // Default to BO for channel types that have no purpose
                        purpose: r.purpose ?? RubyPurpose.BID_OPTIMISATION,
                    })),
                },
                message: req.message,
            }
        );
    });
}

export default function* configSaga() {
    yield* takeRequests(ActionName.UPDATE_CHANNEL, updateConfigSaga);
    yield* takeRequests(ActionName.CREATE_REGIONS, createRegionsSaga);
    yield* takeRequests(ActionName.UPDATE_REGIONS, updateRegionsSaga);
    yield takeEvery(ActionName.UPDATE_DISCOVERY_WEIGHTING, updateDiscoveryWeightingSaga);
    yield* takeRequests(ActionName.UPDATE_REGION_WEIGHTINGS, updateRegionWeightingsSaga);
    yield* takeRequests(ActionName.ADD_BUDGET_PLAN, addBudgetPlanSaga);
    yield* takeRequests(ActionName.REMOVE_BUDGET_PLAN, removeBudgetPlanSaga);
    yield* takeRequests(ActionName.UPDATE_BUDGET_PLAN, updateBudgetPlanSaga);
    yield* takeRequests(ActionName.UPDATE_CHANNEL_NAMES, updateChannelNamesSaga);
    yield* takeRequests(ActionName.GET_CHANNEL_NAMES, getChannelNamesSaga);
    yield* takeRequests(ActionName.SET_ALLOCATIONS, setAllocationsSaga);
    yield* takeRequests(ActionName.ADD_CALENDAR, addCalendarSaga);
    yield* takeRequests(ActionName.LIST_CALENDARS, listCalendarsSaga);
    yield* takeRequests(ActionName.DELETE_CALENDAR, deleteCalendarSaga);
}
