import { type BudgetPlanTemplate } from "../reducers/domain";
import {
    type RubyBudgetPlan,
    type RubyCalendar,
    type RubyCalendarWithEvents,
    type RubyCampaignId,
    type RubyChannelNames,
    type RubyChannelType,
    type RubyChannelUpdateBody,
    type RubyChannelUpdateNamesBody,
    type RubyChannelUpdateResponse,
    type RubyCountry,
    type RubyCreateRegionBody,
    type RubyForcedRegionAllocation,
    type RubyRegion,
    type RubyRegionId,
    type RubyTargetingDimensions,
    type RubyTeamId,
    type RubyUpdateRegionBody,
    type RubyUpdateRegionResponse,
} from "../services/backend/RubyData";
import { type CountryMap } from "../types/utils";
import { requestActions, type Action, type RequestAction } from "./Action";

export enum ConfigActionName {
    UPDATE_CHANNEL = "UPDATE_CHANNEL",
    CREATE_REGIONS = "CREATE_REGIONS",
    UPDATE_REGIONS = "UPDATE_REGIONS",
    UPDATE_REGION_WEIGHTINGS = "UPDATE_REGION_WEIGHTINGS",
    UPDATE_DISCOVERY_WEIGHTING = "UPDATE_DISCOVERY_WEIGHTING",
    ADD_BUDGET_PLAN = "ADD_BUDGET_PLAN",
    UPDATE_BUDGET_PLAN = "UPDATE_BUDGET_PLAN",
    REMOVE_BUDGET_PLAN = "REMOVE_BUDGET_PLAN",

    UPDATE_CHANNEL_NAMES = "UPDATE_CHANNEL_NAMES",
    GET_CHANNEL_NAMES = "GET_CHANNEL_NAMES",
    SET_ALLOCATIONS = "SET_ALLOCATIONS",

    ADD_CALENDAR = "ADD_CALENDAR",
    LIST_CALENDARS = "LIST_CALENDARS",
    DELETE_CALENDAR = "DELETE_CALENDAR",
}

export type ConfigContext = {
    campaignId: RubyCampaignId;
    teamId: RubyTeamId;
    channelType: RubyChannelType;
};

export type CreateRegionsActionPayload = ConfigContext & {
    create: CreateRegionsBody;
    config: Partial<RubyCreateRegionBody>;
    discoveryWeighting: number;
    weightings: CountryMap<number>;
    channelType: RubyChannelType;
    regions: CountryMap<RubyRegion[]>;
    channelTargeting?: RubyTargetingDimensions;
};

export type UpdateChannelActionPayload = ConfigContext & {
    update: RubyChannelUpdateBody;
};

export type UpdateChannelNamesActionPayload = ConfigContext & {
    update: RubyChannelUpdateNamesBody;
};

export type UpdateRegionsActionPayload = ConfigContext & {
    update: Array<RubyUpdateRegionBody & { id: RubyRegionId }>;
};

export type UpdateRegionWeightingsActionPayload = ConfigContext & {
    discoveryWeighting: number;
    weightings: CountryMap<number>;
    channelType: RubyChannelType;
    regions: RubyRegion[];
};

export type UpdateDiscoveryWeightingsActionPayload = ConfigContext & {
    discoveryWeighting: number;
    channelType: RubyChannelType;
};

export type CreateRegionsBody = RubyCountry[];
// As regions are created and then updated with the new weightings this is an update response
export type CreateRegionsResponse = RubyUpdateRegionResponse[];

// eslint-disable-next-line @typescript-eslint/no-namespace
export declare namespace ConfigActions {
    export type AddCalendarAction = RequestAction<
        ConfigActionName.ADD_CALENDAR,
        { data: string; name: string },
        RubyCalendarWithEvents
    >;

    export type ListCalendarsAction = RequestAction<ConfigActionName.LIST_CALENDARS, void, RubyCalendar[]>;

    export type DeleteCalendarAction = RequestAction<ConfigActionName.DELETE_CALENDAR, RubyCalendar["id"], void>;

    export type UpdateChannelAction = RequestAction<
        ConfigActionName.UPDATE_CHANNEL,
        UpdateChannelActionPayload,
        RubyChannelUpdateResponse
    >;

    export type CreateRegionsAction = RequestAction<
        ConfigActionName.CREATE_REGIONS,
        CreateRegionsActionPayload,
        CreateRegionsResponse
    >;

    export type UpdateRegionsAction = RequestAction<
        ConfigActionName.UPDATE_REGIONS,
        UpdateRegionsActionPayload,
        RubyUpdateRegionResponse[]
    >;

    export type UpdateRegionWeightingsAction = RequestAction<
        ConfigActionName.UPDATE_REGION_WEIGHTINGS,
        UpdateRegionWeightingsActionPayload,
        RubyUpdateRegionResponse[]
    >;

    export type UpdateDiscoveryWeightingAction = Action<
        ConfigActionName.UPDATE_DISCOVERY_WEIGHTING,
        UpdateDiscoveryWeightingsActionPayload
    >;

    export type AddBudgetPlanAction = RequestAction<
        ConfigActionName.ADD_BUDGET_PLAN,
        {
            campaignId: RubyCampaignId;
        } & BudgetPlanTemplate,
        RubyBudgetPlan
    >;

    export type UpdateBudgetPlanAction = RequestAction<
        ConfigActionName.UPDATE_BUDGET_PLAN,
        {
            campaignId: RubyCampaignId;
            id: RubyBudgetPlan["id"];
        } & BudgetPlanTemplate,
        RubyBudgetPlan
    >;
    export type RemoveBudgetPlanAction = RequestAction<
        ConfigActionName.REMOVE_BUDGET_PLAN,
        {
            campaignId: RubyCampaignId;
            id: RubyBudgetPlan["id"];
        },
        void
    >;

    export type UpdateChannelNamesAction = RequestAction<
        ConfigActionName.UPDATE_CHANNEL_NAMES,
        UpdateChannelNamesActionPayload,
        RubyChannelNames
    >;

    export type GetChannelNamesAction = RequestAction<
        ConfigActionName.GET_CHANNEL_NAMES,
        { campaignId: RubyCampaignId; teamId: RubyTeamId; channelType: RubyChannelType },
        RubyChannelNames
    >;

    export type SetAllocationsAction = RequestAction<
        ConfigActionName.SET_ALLOCATIONS,
        { campaignId: RubyCampaignId; regions: RubyForcedRegionAllocation[]; message: string },
        null
    >;
}

export type ConfigActionType =
    | ConfigActions.UpdateChannelAction
    | ConfigActions.CreateRegionsAction
    | ConfigActions.UpdateRegionsAction
    | ConfigActions.UpdateRegionWeightingsAction
    | ConfigActions.AddBudgetPlanAction
    | ConfigActions.UpdateBudgetPlanAction
    | ConfigActions.RemoveBudgetPlanAction
    | ConfigActions.UpdateChannelNamesAction
    | ConfigActions.GetChannelNamesAction
    | ConfigActions.SetAllocationsAction
    | ConfigActions.UpdateDiscoveryWeightingAction
    | ConfigActions.AddCalendarAction
    | ConfigActions.ListCalendarsAction
    | ConfigActions.DeleteCalendarAction;

export const channelConfigActions = {
    update: requestActions<ConfigActions.UpdateChannelAction>(ConfigActionName.UPDATE_CHANNEL),
    updateCampaignNames: requestActions<ConfigActions.UpdateChannelNamesAction>(ConfigActionName.UPDATE_CHANNEL_NAMES),
    getChannelNamesAction: requestActions<ConfigActions.GetChannelNamesAction>(ConfigActionName.GET_CHANNEL_NAMES),
};

export const calendarActions = {
    add: requestActions<ConfigActions.AddCalendarAction>(ConfigActionName.ADD_CALENDAR),
    list: requestActions<ConfigActions.ListCalendarsAction>(ConfigActionName.LIST_CALENDARS),
    delete: requestActions<ConfigActions.DeleteCalendarAction>(ConfigActionName.DELETE_CALENDAR),
};

export const regionConfigActions = {
    create: requestActions<ConfigActions.CreateRegionsAction>(ConfigActionName.CREATE_REGIONS),
    update: requestActions<ConfigActions.UpdateRegionsAction>(ConfigActionName.UPDATE_REGIONS),
    updateWeightings: requestActions<ConfigActions.UpdateRegionWeightingsAction>(
        ConfigActionName.UPDATE_REGION_WEIGHTINGS
    ),
    updateDiscoveryWeighting: function (
        req: UpdateDiscoveryWeightingsActionPayload
    ): ConfigActions.UpdateDiscoveryWeightingAction {
        return {
            type: ConfigActionName.UPDATE_DISCOVERY_WEIGHTING,
            payload: req,
        };
    },
};

export const budgetConfigActions = {
    add: requestActions<ConfigActions.AddBudgetPlanAction>(ConfigActionName.ADD_BUDGET_PLAN),
    update: requestActions<ConfigActions.UpdateBudgetPlanAction>(ConfigActionName.UPDATE_BUDGET_PLAN),
    delete: requestActions<ConfigActions.RemoveBudgetPlanAction>(ConfigActionName.REMOVE_BUDGET_PLAN),
    setAllocations: requestActions<ConfigActions.SetAllocationsAction>(ConfigActionName.SET_ALLOCATIONS),
};
