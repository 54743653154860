import type { CreationState } from "@/reducers/domain";
import { broadcastService, creationService, orderService, rubyService } from "@/services";
import { CampaignObjectiveType, ExperimentAlignment, ExperimentType } from "@/types/CampaignObjective";
import type { CountryMap } from "@/types/utils";
import { getDateMidPoint } from "@/utilities/date";
import { getInterlacedScheduleHours } from "@/utilities/demographics";
import { push } from "@lagunovsky/redux-react-router";
import difference from "lodash/difference";
import intersection from "lodash/intersection";
import omit from "lodash/omit";
import moment from "moment";
import { put, takeEvery, takeLatest } from "redux-saga/effects";
import { type ExtractActionTypeReq, type ExtractRequestActionRequestType } from "../actions/Action";
import { ActionName } from "../actions/ActionType";
import {
    campaignActions,
    getChannelAction,
    getIOSAppAction,
    listBudgetPlansAction,
    listRegionsAction,
} from "../actions/campaignActions";
import {
    createCampaignAction,
    resetCreationAction,
    restoreCreationStateAction,
    setCreationAppAction,
    setCreationBidsAction,
    setCreationBudgetAction,
    setCreationChannelAction,
    setCreationKeywordsAction,
    setCreationOrganisationAction,
    setCreationProductPageAction,
    setCreationRegionsAction,
    setCreationTargetingAction,
    updateCreationProgressAction,
    type CreationActions,
} from "../actions/creationActions";
import { negativeKeywordsActions, seedKeywordsActions, targetingKeywordsActions } from "../actions/keywordsActions";
import { type MetaActions } from "../actions/metaActions";
import { setActiveTeamAction } from "../actions/uiActions";
import {
    selectActiveCountries,
    selectApp,
    selectCampaign,
    selectCampaignActiveBudget,
    selectChannel,
    selectRegions,
} from "../selectors/campaignSelectors";
import { selectNegativeKeywords, selectSeedKeywords, selectTargetingKeywords } from "../selectors/keywordsSelectors";
import { selectPaymentDetails, selectTeam, selectTeamOrganisations } from "../selectors/teamSelectors";
import { BroadcastType } from "../services/BroadcastService";
import {
    RubyBidStrategy,
    RubyBudgetPlanStatus,
    RubyCampaignStatus,
    RubyChannelStatus,
    RubyChannelStructure,
    RubyChannelType,
    RubyKeywordMatchType,
    RubyKeywordStatus,
    RubyPaymentType,
    RubyPurpose,
    RubyRegionStatus,
    type RubyASRRegion,
    type RubyCampaign,
    type RubyCampaignId,
    type RubyChannel,
    type RubyCountry,
    type RubyKeyword,
    type RubyRegion,
    type RubyTargetingDimensions,
    type RubyTeamId,
} from "../services/backend/RubyData";
import { DEFAULT_WEEKLY_OVERSPEND, convertBudgetPlanToTemplate } from "../utilities/budget";
import { getCountry } from "../utilities/country";
import { blendValues } from "../utilities/number";
import { runRequestAction, selectAsyncDataFromState, selectFromState, takeRequests } from "../utilities/saga";
import { delay, seconds } from "../utilities/time";
import { isChannelWithKeywords, isChannelWithPurposes, isRegionWithPurpose } from "../utilities/types";
import { getUrl } from "../utilities/url";
import { DEFAULT_DISCOVERY_WEIGHTING } from "../utilities/vars";
import { updateWeightings } from "./configSaga";

type FinalCreationState = CreationState & ExtractActionTypeReq<CreationActions.CreateCampaignAction>;

function* createCampaignSaga(action: ExtractRequestActionRequestType<CreationActions.CreateCampaignAction>) {
    const creationState = yield* selectFromState((state) => state.creation);
    const request: FinalCreationState = {
        ...creationState,
        ...action.payload.request,
    };
    yield put(createCampaignAction.call(request));

    request.objective = {
        objectiveType: CampaignObjectiveType.AD_CAMPAIGN,
        tags: [],
        notes: request.notes,
    };

    const campaignIds: RubyCampaignId[] = [];

    try {
        if (!request.createSeparateRegions) {
            const {
                campaign: { id: campaignId },
            } = yield* createSingleCampaign(request);
            campaignIds.push(campaignId);
        } else {
            for (const [i, region] of request.regions.entries()) {
                const {
                    campaign: { id: campaignId },
                } = yield* createSingleCampaign(
                    {
                        ...request,
                        name: `${request.name} - ${getCountry(region).name}`,
                        regions: [region],
                        costPerDownload: request.regionalCostPerDownload[region] ?? request.costPerDownload,
                        regionalCostPerDownload: {},
                        startingBid: request.regionalStartingBids[region] ?? request.startingBid,
                        regionalStartingBids: {},
                    },
                    (percent) => {
                        const start = i / request.regions.length;
                        const end = (i + 1) / request.regions.length;
                        return blendValues(start, end, percent);
                    }
                );
                campaignIds.push(campaignId);
            }
        }

        yield* postCreationFlow(
            request,
            campaignIds.length === 1
                ? getUrl.campaignMetrics(request.team, campaignIds[0])
                : getUrl.campaigns(request.team)
        );
    } catch (error) {
        yield put(createCampaignAction.error(request, error instanceof Error ? error.message : (error as string)));
    }
}

function* createExperimentSequential(request: FinalCreationState) {
    // Just making TS happy about the typing
    if (request.objective.objectiveType !== CampaignObjectiveType.EXPERIMENT) {
        throw new Error("Attempted to create an experiment without the correct typing");
    }

    const budgetAStart = request.experimentTimeRange[0];
    const budgetBEnd = request.experimentTimeRange[1];
    const budgetAEnd = getDateMidPoint(budgetAStart, budgetBEnd, "day").endOf("day");
    const budgetBStart = getDateMidPoint(budgetAStart, budgetBEnd, "day").add(1, "day").startOf("day");

    request.objective.durationA = [budgetAStart, budgetAEnd.valueOf()];
    request.objective.durationB = [budgetBStart.valueOf(), budgetBEnd];

    request.budget = {
        ...request.budget,
        timeRange: [budgetAStart, budgetAEnd.valueOf()],
        totalAmount: request.budget.totalAmount / 2,
    };

    const { campaign, targetingKeywords, regions } = yield* createSingleCampaign(
        request,
        (percent) => percent * 0.9,
        true
    );
    yield put(updateCreationProgressAction(0.9));

    yield rubyService.budgets.createBudgetPlan(
        { campaignId: campaign.id },
        {
            totalBudget: request.budget.totalAmount,
            details: {
                weekdayOverspend: {
                    ...DEFAULT_WEEKLY_OVERSPEND,
                    ...(request.budget.weeklyOverspend ?? undefined),
                },
            },
            policy: request.budget.policy,
            status: RubyBudgetPlanStatus.ACTIVE,
            start: budgetBStart.valueOf(),
            end: budgetBEnd,
            method: request.budget.method,
        }
    );
    yield put(updateCreationProgressAction(0.95));

    if (request.experimentType === ExperimentType.KEYWORD_SET) {
        if (!isChannelWithKeywords(campaign.channelType, null)) {
            throw new Error("Unable to run keyword experiment on a channel without keywords");
        }

        const region = regions.find(
            (region) =>
                isRegionWithPurpose(campaign.channelType, region) && region.purpose === RubyPurpose.BID_DISCOVERY
        );

        const exactKeywordStringsToStop = difference(request.targetingKeywords, request.experimentKeywords);
        const broadKeywordStringsToStop = difference(request.targetingKeywordsBroad, request.experimentKeywordsBroad);

        const exactKeywordStringsToAdd = difference(request.experimentKeywords, request.targetingKeywords);
        const broadKeywordStringsToAdd = difference(request.experimentKeywordsBroad, request.targetingKeywordsBroad);

        const exactKeywordStringsToKeep = intersection(request.targetingKeywords, request.experimentKeywords);
        const broadKeywordStringsToKeep = intersection(request.targetingKeywordsBroad, request.experimentKeywordsBroad);

        const keywordsToStop = exactKeywordStringsToStop.map((keyword) =>
            targetingKeywords.find((kw) => kw.text === keyword)
        );
        keywordsToStop.push(
            ...broadKeywordStringsToStop.map((keyword) => targetingKeywords.find((kw) => kw.text === keyword))
        );

        const keywordsToKeep = exactKeywordStringsToKeep.map((keyword) =>
            targetingKeywords.find((kw) => kw.text === keyword)
        );
        keywordsToKeep.push(
            ...broadKeywordStringsToKeep.map((keyword) => targetingKeywords.find((kw) => kw.text === keyword))
        );

        const keywordsToAdd = (yield Promise.all([
            ...exactKeywordStringsToAdd.map((keyword) => {
                return rubyService.appleChannels.createAsrChannelTargetingKeyword(
                    {
                        campaignId: campaign.id,
                        teamId: request.team,
                    },
                    {
                        text: keyword.toLocaleLowerCase(),
                        match: RubyKeywordMatchType.EXACT,
                        status: RubyKeywordStatus.ACTIVE,
                    }
                );
            }),
            ...broadKeywordStringsToAdd.map((keyword) => {
                return rubyService.appleChannels.createAsrChannelTargetingKeyword(
                    {
                        campaignId: campaign.id,
                        teamId: request.team,
                    },
                    {
                        text: keyword.toLocaleLowerCase(),
                        match: RubyKeywordMatchType.BROAD,
                        status: RubyKeywordStatus.ACTIVE,
                    }
                );
            }),
        ])) as RubyKeyword[];

        yield rubyService.appleChannels.createScheduledKeywordAssignmentsChanges(
            {
                campaignId: campaign.id,
                teamId: request.team,
            },
            {
                changeAt: budgetBStart.valueOf(),
                targetState: {
                    assignments: [
                        ...keywordsToStop.map((keyword) => {
                            return {
                                keywordId: keyword.id,
                                regionId: region.id,
                                status: RubyKeywordStatus.DISABLED,
                            };
                        }),
                        ...[...keywordsToAdd, ...keywordsToKeep].map((keyword) => {
                            return {
                                keywordId: keyword.id,
                                regionId: region.id,
                                status: RubyKeywordStatus.ACTIVE,
                            };
                        }),
                    ],
                },
            }
        );
    } else if (request.experimentType === ExperimentType.BID_PRICE) {
        if (!isChannelWithKeywords(campaign.channelType, null)) {
            throw new Error("Unable to run keyword experiment on a channel without keywords");
        }
        const region = regions.find(
            (region) =>
                isRegionWithPurpose(campaign.channelType, region) && region.purpose === RubyPurpose.BID_DISCOVERY
        );

        yield rubyService.appleChannels.createScheduledKeywordAssignmentsChanges(
            {
                campaignId: campaign.id,
                teamId: request.team,
            },
            {
                changeAt: budgetBStart.valueOf(),
                targetState: {
                    assignments: [
                        ...targetingKeywords.map((keyword) => {
                            return {
                                keywordId: keyword.id,
                                regionId: region.id,
                                status: RubyKeywordStatus.ACTIVE,
                                forceBidAmount: request.experimentBidAmount,
                                bidAmount: request.experimentBidAmount,
                            };
                        }),
                    ],
                },
            }
        );
    } else {
        yield rubyService.appleChannels.createScheduledChannelChanges(
            {
                teamId: request.team,
                campaignId: campaign.id,
            },
            {
                changeAt: budgetBStart.valueOf(),
                targetState: request.objective.stateB,
            }
        );
    }
}

function* createExperimentInterlaced(request: FinalCreationState) {
    const activeHours = getInterlacedScheduleHours(
        request.experimentTimeCadence,
        request.experimentTimeCadenceAlignment
    );
    request.targeting = {
        ...request.targeting,
        daypart: {
            userTime: {
                included: activeHours.activeHoursA,
            },
        },
    };

    yield* createDualExperimentCampaigns(request, {
        productPage: request.experimentProductPage,
        targeting: {
            ...(request.experimentTargeting ?? request.targeting),
            daypart: {
                userTime: {
                    included: activeHours.activeHoursB,
                },
            },
        },
        keywords: request.experimentKeywords,
        keywordsBroad: request.experimentKeywordsBroad,
        bidAmount: request.experimentBidAmount,
    });
}

function* createExperimentRegional(request: FinalCreationState) {
    if (
        !request.experimentRegion &&
        !request.experimentTargeting?.adminArea?.included?.length &&
        !request.experimentTargeting?.locality?.included?.length
    ) {
        throw new Error("Unable to create experiment without second region");
    }

    yield* createDualExperimentCampaigns(request, {
        productPage: request.experimentProductPage,
        targeting: request.experimentTargeting,
        keywords: request.experimentKeywords,
        keywordsBroad: request.experimentKeywordsBroad,
        bidAmount: request.experimentBidAmount,
        regions: request.experimentRegion ? [request.experimentRegion] : request.regions,
    });
}

function* createDualExperimentCampaigns(
    request: FinalCreationState,
    experiment: {
        productPage?: string;
        targeting?: RubyTargetingDimensions;
        regions?: RubyCountry[];
        keywords?: string[];
        keywordsBroad?: string[];
        bidAmount?: number;
    }
) {
    // Just making TS happy about the typing
    if (request.objective.objectiveType !== CampaignObjectiveType.EXPERIMENT) {
        throw new Error("Attempted to create an experiment without the correct typing");
    }

    request.budget = {
        ...request.budget,
        totalAmount: request.budget.totalAmount / 2,
    };

    const {
        campaign: { id: campaignIdA },
    } = yield* createSingleCampaign(request, (percent) => blendValues(0, 0.45, percent), true);
    yield put(updateCreationProgressAction(0.45));

    request.productPage = experiment.productPage ?? request.productPage;
    request.targeting = experiment.targeting ?? request.targeting;
    request.regions = experiment.regions ?? request.regions;
    request.targetingKeywords = experiment.keywords ?? request.targetingKeywords;
    request.targetingKeywordsBroad = experiment.keywordsBroad ?? request.targetingKeywordsBroad;
    request.lockedBidPrice = experiment.bidAmount ?? request.lockedBidPrice;

    request.objective.campaignIdA = campaignIdA;

    const {
        campaign: { id: campaignIdB },
    } = yield* createSingleCampaign(request, (percent) => blendValues(0.45, 0.9, percent), true);
    yield put(updateCreationProgressAction(0.9));
    request.objective.campaignIdB = campaignIdB;

    yield rubyService.accounts.updateCampaign(
        { campaignId: campaignIdA, teamId: request.team },
        {
            objective: JSON.stringify(request.objective),
        }
    );
    yield put(updateCreationProgressAction(0.95));
    yield rubyService.accounts.updateCampaign(
        { campaignId: campaignIdB, teamId: request.team },
        {
            objective: JSON.stringify(request.objective),
        }
    );
}

function* createExperimentSaga(action: ExtractRequestActionRequestType<CreationActions.CreateExperimentAction>) {
    const creationState = yield* selectFromState((state) => state.creation);
    const request: FinalCreationState = {
        ...creationState,
        ...action.payload.request,
        createSeparateRegions: false,
        seedKeywords: null,
        regionalStartingBids: {},
        discoveryWeighting: 1,
        structure: RubyChannelStructure.CAMPAIGN_PER_COUNTRY,
    };
    yield put(createCampaignAction.call(request));

    try {
        request.objective = {
            objectiveType: CampaignObjectiveType.EXPERIMENT,
            alignment: request.experimentAlignment,
            duration: request.experimentTimeRange,
            experimentType: request.experimentType,
            totalBudget: request.budget.totalAmount,
            stateA: {
                productPageRef:
                    request.experimentType === ExperimentType.PRODUCT_PAGE ? request.productPage : undefined,
                targetingDimensions:
                    request.experimentType === ExperimentType.DEMOGRAPHICS
                        ? omit(request.targeting, "adminArea", "locality")
                        : undefined,
                keywords: request.experimentType === ExperimentType.KEYWORD_SET ? request.targetingKeywords : undefined,
                keywordsBroad:
                    request.experimentType === ExperimentType.KEYWORD_SET ? request.targetingKeywordsBroad : undefined,
                bidAmount: request.experimentType === ExperimentType.BID_PRICE ? request.lockedBidPrice : undefined,
            },
            stateB: {
                productPageRef:
                    request.experimentType === ExperimentType.PRODUCT_PAGE ? request.experimentProductPage : undefined,
                targetingDimensions:
                    request.experimentType === ExperimentType.DEMOGRAPHICS
                        ? omit(request.experimentTargeting, "adminArea", "locality")
                        : undefined,
                keywords:
                    request.experimentType === ExperimentType.KEYWORD_SET ? request.experimentKeywords : undefined,
                keywordsBroad:
                    request.experimentType === ExperimentType.KEYWORD_SET ? request.experimentKeywordsBroad : undefined,
                bidAmount:
                    request.experimentType === ExperimentType.BID_PRICE ? request.experimentBidAmount : undefined,
            },
            notes: request.notes,
        };

        switch (request.experimentAlignment) {
            case ExperimentAlignment.SEQUENTIAL:
                yield* createExperimentSequential(request);
                break;
            case ExperimentAlignment.INTERLACED:
                yield* createExperimentInterlaced(request);
                break;
            case ExperimentAlignment.REGIONAL:
                yield* createExperimentRegional(request);
                break;
            default:
                throw new Error("Unknown experiment alignment");
        }

        yield put(updateCreationProgressAction(1));
        yield* postCreationFlow(request, getUrl.experiments(request.team));
    } catch (error) {
        yield put(createCampaignAction.error(request, error instanceof Error ? error.message : (error as string)));
    }
}

/**
 * This is DRY for the completion of the above functions
 */
function* postCreationFlow(request: FinalCreationState, redirectUrl: string) {
    yield put(createCampaignAction.success(request));
    yield put(campaignActions.list.request(request.team, true));
    yield put(setActiveTeamAction(request.team));
    yield delay(seconds(2)); // Purely ascetic to let the animation play
    yield put(push(redirectUrl));
    yield delay(seconds(2)); // Let the UI leave safely
    yield put(resetCreationAction());
}

function* createSingleCampaign(
    request: FinalCreationState,
    precentScaler: (percent: number) => number = (p) => p,
    disableAlgorithm = false
): Generator<
    unknown,
    {
        campaign: RubyCampaign;
        channel: RubyChannel;
        regions: RubyRegion[];
        targetingKeywords?: RubyKeyword[];
    },
    unknown
> {
    let campaign: RubyCampaign;
    let channel: RubyChannel;
    const regions: RubyRegion[] = [];
    let targetingKeywords: RubyKeyword[] = [];
    try {
        yield put(updateCreationProgressAction(precentScaler(0.05)));

        const team = yield* selectFromState((state) => selectTeam(state, request.team));

        campaign = (yield rubyService.accounts.createCampaign(
            { id: request.team },
            {
                name: request.name ?? `New campaign ${Date.now()}`,
                description: request.description,
                channelType: request.channelType,
                objective: request.objective ? JSON.stringify(request.objective) : "",
                status: RubyCampaignStatus.ACTIVE,
            }
        )) as RubyCampaign;

        const idPayload = {
            campaignId: campaign.id,
            teamId: request.team,
        };

        const enableKeywordDiscovery = request.seedKeywords !== null;

        const org = team.data?.tier?.hosted
            ? undefined
            : yield* selectFromState((state) =>
                  selectTeamOrganisations(state, request.team).data.find((org) => org.orgRef === request.organisationId)
              );

        const paymentDetails = yield* selectFromState((state) => selectPaymentDetails(state, team.data.id));

        yield put(updateCreationProgressAction(precentScaler(0.1)));
        const channelRequest = {
            applicationRef: request.app.trackId,
            cpdGoal: request.costPerDownload,
            defaultBidAmount: request.startingBid,
            status: RubyChannelStatus.ACTIVE,
            structure:
                request.structure ??
                (isChannelWithPurposes(request.channelType)
                    ? RubyChannelStructure.DISCOVERY_AND_OPTIMISATION_CAMPAIGNS
                    : RubyChannelStructure.CAMPAIGN_PER_REGION),
            orgRef: org ? org.orgRef : undefined,
            storefront: request.regions[0],
            targetingDimensions: request.targeting,
            invoiceDetails:
                !!org && org.applePaymentModel === "LOC"
                    ? {
                          billingEmail: paymentDetails?.data?.email,
                          buyerEmail: paymentDetails?.data?.email,
                          buyerName: paymentDetails?.data?.name,
                          orderNumber: request.orderNumber || undefined,
                      }
                    : undefined,
            purposes: enableKeywordDiscovery
                ? undefined
                : Object.values(RubyPurpose).filter((p) => p !== RubyPurpose.KEYWORD_DISCOVERY),
        };

        channel = (yield (() => {
            switch (request.channelType) {
                case RubyChannelType.APPLE_SEARCH_RESULTS:
                    return rubyService.appleChannels.createAsrChannel(idPayload, {
                        ...channelRequest,
                        keywordBidStrategy: disableAlgorithm ? RubyBidStrategy.NONE : RubyBidStrategy.ALGORITHM,
                        productPageRef: request.productPage,
                    });
                case RubyChannelType.APPLE_SEARCH_TABS:
                    return rubyService.appleChannels.createAstChannel(idPayload, {
                        ...channelRequest,
                    });
                case RubyChannelType.APPLE_TODAY_TABS:
                    return rubyService.appleChannels.createAttChannel(idPayload, {
                        ...channelRequest,
                        productPageRef: request.productPage,
                    });
                case RubyChannelType.PRODUCT_PAGE_BROWSE:
                    return rubyService.appleChannels.createPpbChannel(idPayload, {
                        ...channelRequest,
                    });
            }
        })()) as RubyChannel;

        yield put(updateCreationProgressAction(precentScaler(0.2)));

        for (const [i, countryCode] of request.regions.entries()) {
            const cpdGoal = request.regionalCostPerDownload[countryCode];
            const defaultBidAmount = request.regionalStartingBids[countryCode];
            const regionPayload = {
                budgetWeighting: 0, // Set later
                country: countryCode,
                status: RubyRegionStatus.ACTIVE,
                cpdGoal: cpdGoal > 0 ? cpdGoal : undefined,
                defaultBidAmount: defaultBidAmount > 0 ? defaultBidAmount : undefined,
            };

            const region = (yield (() => {
                switch (request.channelType) {
                    case RubyChannelType.APPLE_SEARCH_RESULTS:
                        return rubyService.appleChannels
                            .createAsrChannelRegion(idPayload, regionPayload)
                            .then((res) => res.results);
                    case RubyChannelType.APPLE_SEARCH_TABS:
                        return rubyService.appleChannels
                            .createAstChannelRegion(idPayload, regionPayload)
                            .then((res) => res.results);
                    case RubyChannelType.APPLE_TODAY_TABS:
                        return rubyService.appleChannels
                            .createAttChannelRegion(idPayload, regionPayload)
                            .then((res) => res.results);
                    case RubyChannelType.PRODUCT_PAGE_BROWSE:
                        return rubyService.appleChannels
                            .createPpbChannelRegion(idPayload, regionPayload)
                            .then((res) => res.results);
                }
            })()) as RubyRegion[];

            // TODO: This should be part of the creation call, needs API change.
            yield updateWeightings({
                ...idPayload,
                channelType: request.channelType,
                regions: [...region],
                weightings: { [countryCode]: 1 }, // TODO make state value
                discoveryWeighting: request.discoveryWeighting ?? DEFAULT_DISCOVERY_WEIGHTING,
            });
            const progress = blendValues(0.3, 0.5, i / request.regions.length);
            yield put(updateCreationProgressAction(precentScaler(progress)));
            regions.push(...region);
        }
        yield put(updateCreationProgressAction(precentScaler(0.5)));

        // Half the startingBid for all KD regions
        for (const region of regions) {
            if (isRegionWithPurpose(request.channelType, region) && region.purpose === RubyPurpose.KEYWORD_DISCOVERY) {
                const defaultBidAmount = request.regionalStartingBids[region.country] ?? request.startingBid;
                yield rubyService.appleChannels.updateAsrChannelRegion(
                    { ...idPayload, regionId: region.id },
                    {
                        defaultBidAmount: defaultBidAmount / 2,
                    }
                );
            }
        }
        yield put(updateCreationProgressAction(precentScaler(0.55)));

        if (isChannelWithKeywords(request.channelType, channel)) {
            if (enableKeywordDiscovery) {
                yield Promise.all(
                    request.seedKeywords.map((keyword) => {
                        return rubyService.appleChannels.createAsrChannelSeedKeyword(idPayload, {
                            text: keyword.toLocaleLowerCase(),
                            match: RubyKeywordMatchType.EXACT,
                            status: RubyKeywordStatus.ACTIVE,
                        });
                    })
                );
            }

            yield put(updateCreationProgressAction(precentScaler(0.6)));

            targetingKeywords = (yield Promise.all([
                ...request.targetingKeywords.map((keyword) => {
                    return rubyService.appleChannels.createAsrChannelTargetingKeyword(idPayload, {
                        text: keyword.toLocaleLowerCase(),
                        match: RubyKeywordMatchType.EXACT,
                        status: RubyKeywordStatus.ACTIVE,
                    });
                }),
                ...request.targetingKeywordsBroad.map((keyword) => {
                    return rubyService.appleChannels.createAsrChannelTargetingKeyword(idPayload, {
                        text: keyword.toLocaleLowerCase(),
                        match: RubyKeywordMatchType.BROAD,
                        status: RubyKeywordStatus.ACTIVE,
                    });
                }),
            ])) as RubyKeyword[];

            yield put(updateCreationProgressAction(precentScaler(0.65)));

            yield rubyService.appleChannels.updateAsrChannelKeywordAssignments(idPayload, {
                assignments: targetingKeywords.flatMap((keyword) =>
                    regions
                        // TODO - if bid discovery could be disabled this filter would need to change
                        .filter((region) => (region as RubyASRRegion).purpose === RubyPurpose.BID_DISCOVERY)
                        .map((region) => ({
                            keywordId: keyword.id,
                            status: RubyKeywordStatus.ACTIVE,
                            regionId: region.id,
                            forceBidAmount: request.lockedBidPrice ?? undefined,
                        }))
                ),
            });

            yield put(updateCreationProgressAction(precentScaler(0.7)));

            yield Promise.all(
                request.negativeKeywords.map((keyword) => {
                    return rubyService.appleChannels.createAsrChannelNegativeKeyword(idPayload, {
                        text: keyword.toLocaleLowerCase(),
                        match: RubyKeywordMatchType.EXACT,
                        status: RubyKeywordStatus.ACTIVE,
                    });
                })
            );
        }

        yield put(updateCreationProgressAction(precentScaler(0.8)));

        //Budget
        yield rubyService.budgets.createBudgetPlan(
            { campaignId: campaign.id },
            {
                totalBudget: request.budget.totalAmount,
                details: {
                    weekdayOverspend: {
                        ...DEFAULT_WEEKLY_OVERSPEND,
                        ...(request.budget.weeklyOverspend ?? undefined),
                    },
                },
                policy: request.budget.policy,
                status: RubyBudgetPlanStatus.ACTIVE,
                start: request.budget.timeRange[0],
                end: request.budget.timeRange[1],
                method: request.budget.method,
            }
        );

        yield put(updateCreationProgressAction(precentScaler(0.85)));

        if (team.data.tier.payment === RubyPaymentType.PREPAY) {
            yield orderService.payOrder({
                teamId: request.team,
                amount: request.budget.totalAmount,
                campaignId: campaign.id,
            });
        }

        yield put(updateCreationProgressAction(precentScaler(0.9)));

        switch (request.channelType) {
            case RubyChannelType.APPLE_SEARCH_RESULTS:
                yield rubyService.appleChannels.syncAsrChannel(idPayload);
                break;
            case RubyChannelType.APPLE_SEARCH_TABS:
                yield rubyService.appleChannels.syncAstChannel(idPayload);
                break;
            case RubyChannelType.APPLE_TODAY_TABS:
                yield rubyService.appleChannels.syncAttChannel(idPayload);
                break;
            case RubyChannelType.PRODUCT_PAGE_BROWSE:
                yield rubyService.appleChannels.syncPpbChannel(idPayload);
                break;
        }

        yield put(updateCreationProgressAction(precentScaler(1)));
        broadcastService.post(BroadcastType.CREATED_CAMPAIGN, { campaignId: campaign.id, teamId: request.team });
    } catch (error) {
        console.error("Campaign creation failed", error);
        //Attempt to roll back
        try {
            if (campaign && channel) {
                const idPayload = {
                    teamId: request.team,
                    campaignId: campaign.id,
                };
                switch (request.channelType) {
                    case RubyChannelType.APPLE_SEARCH_RESULTS: {
                        yield rubyService.appleChannels.deleteAsrChannel(idPayload);
                        break;
                    }
                    case RubyChannelType.APPLE_SEARCH_TABS: {
                        yield rubyService.appleChannels.deleteAstChannel(idPayload);
                        break;
                    }
                    case RubyChannelType.APPLE_TODAY_TABS: {
                        yield rubyService.appleChannels.deleteAttChannel(idPayload);
                        break;
                    }
                    case RubyChannelType.PRODUCT_PAGE_BROWSE: {
                        yield rubyService.appleChannels.deletePpbChannel(idPayload);
                        break;
                    }
                }
            }
            if (campaign) {
                yield rubyService.accounts.deleteCampaign({
                    teamId: request.team,
                    campaignId: campaign.id,
                });
            }
        } catch (err) {
            console.error("Campaign creation rollback failed", err);
        }
        throw error;
    }
    return {
        campaign,
        channel,
        regions,
        targetingKeywords,
    };
}

function* externalNewCampaign(action: MetaActions.BroadcastMessageAction) {
    if (action.payload.type === BroadcastType.CREATED_CAMPAIGN) {
        const payload = action.payload.payload as { campaignId: RubyCampaignId; teamId: RubyTeamId };
        yield put(campaignActions.list.request(payload.teamId, true));
    }
}

function* storeCreationState() {
    const state = yield* selectFromState((state) => state.creation);
    if (state.team > 0) {
        creationService.store(state);
    }
}

function* loadCreationState(action: ExtractRequestActionRequestType<CreationActions.RestoreCreationStateAction>) {
    const activeState = yield* selectFromState((state) => state.creation);
    yield* runRequestAction(action, restoreCreationStateAction, () => {
        const storedState = creationService.retrieve();
        if (storedState?.team > 0 && activeState?.team < 1) {
            return Promise.resolve(storedState);
        }
    });
}

function* clearCreationState() {
    yield creationService.clear();
}

function* triggerClearCreationState() {
    yield put(resetCreationAction());
}

function* populateCampaignDetails(action: CreationActions.SetCreationTeamAction) {
    if (action.payload.campaignId) {
        const campaignId = action.payload.campaignId;
        const teamId = action.payload.teamId;

        yield put(
            campaignActions.get.request({
                campaignId,
                teamId,
            })
        );
        const campaign = yield* selectAsyncDataFromState((state) => selectCampaign(state, campaignId));

        yield put(
            getChannelAction.request({
                campaignId,
                teamId,
                channelType: campaign.channelType,
            })
        );
        const channel = yield* selectAsyncDataFromState((state) => selectChannel(state, campaignId));
        yield put(setCreationChannelAction(campaign.channelType));
        yield put(setCreationOrganisationAction(channel.orgRef));
        yield put(setCreationTargetingAction(channel.targetingDimensions));
        yield put(setCreationProductPageAction(channel.productPageRef));

        yield put(
            getIOSAppAction.request({
                appId: channel.applicationRef,
                teamId,
                countryCode: channel.storefront,
            })
        );
        const app = yield* selectAsyncDataFromState((state) => selectApp(state, channel.applicationRef));
        yield put(setCreationAppAction(app));

        yield put(listBudgetPlansAction.request(campaignId));
        const budget = yield* selectAsyncDataFromState((state) => selectCampaignActiveBudget(state, campaignId));
        if (budget) {
            const budgetTemplate = convertBudgetPlanToTemplate(budget);
            const offsetAmount = moment
                .utc(budgetTemplate.timeRange[0])
                .startOf("day")
                .diff(moment.utc().startOf("day"), "days");
            budgetTemplate.timeRange[0] = moment.utc().startOf("day").valueOf();
            budgetTemplate.timeRange[1] = moment
                .utc(budgetTemplate.timeRange[1])
                .add(offsetAmount, "days")
                .endOf("day")
                .valueOf();
            yield put(setCreationBudgetAction(budgetTemplate));
        }

        yield put(
            listRegionsAction.request({
                campaignId,
                teamId,
                channelType: campaign.channelType,
            })
        );

        const isExperiment = yield* selectFromState((state) =>
            state.router.location.pathname.includes(getUrl.experimentCreation())
        );
        const regions = yield* selectAsyncDataFromState((state) => selectRegions(state, campaignId));
        const countries = yield* selectAsyncDataFromState((state) => selectActiveCountries(state, campaignId));
        let activeCountries = countries.activeCountries;

        if (isExperiment && activeCountries.length > 1) {
            activeCountries = [activeCountries[0]];
        }

        yield put(setCreationRegionsAction(activeCountries, false));

        const regionalCostPerDownload: CountryMap<number> = {};
        const regionalStartingBid: CountryMap<number> = {};
        regions.forEach((region) => {
            if (region.cpdGoal > 0) {
                regionalCostPerDownload[region.country] = region.cpdGoal;
            }
            if (regionalStartingBid[region.country] ?? 0 < region.defaultBidAmount) {
                regionalStartingBid[region.country] = region.defaultBidAmount;
            }
        });
        yield put(
            setCreationBidsAction({
                costPerDownloadTarget: channel.cpdGoal,
                startingBid: channel.defaultBidAmount,
                regionalCostPerDownload,
                regionalStartingBid,
            })
        );

        if (campaign && isChannelWithKeywords(campaign.channelType, channel)) {
            yield put(
                seedKeywordsActions.list.request({
                    campaignId,
                    teamId,
                })
            );
            yield put(
                targetingKeywordsActions.list.request({
                    campaignId,
                    teamId,
                })
            );
            yield put(
                negativeKeywordsActions.list.request({
                    campaignId,
                    teamId,
                })
            );
            yield delay(100);
            const seedKeywords = yield* selectAsyncDataFromState((state) => selectSeedKeywords(state, campaignId));
            const targetingKeywords = yield* selectAsyncDataFromState((state) =>
                selectTargetingKeywords(state, campaignId)
            );
            const negativeKeywords = yield* selectAsyncDataFromState((state) =>
                selectNegativeKeywords(state, campaignId)
            );

            const discoveryEnabled = channel?.purposes?.includes(RubyPurpose.KEYWORD_DISCOVERY);

            const keywords = {
                seedKeywords: discoveryEnabled ? seedKeywords.map((kw) => kw.text) : null,
                targetingKeywords: targetingKeywords
                    .filter((kw) => kw.match === RubyKeywordMatchType.EXACT)
                    .map((kw) => kw.text),
                targetingBroadKeywords: targetingKeywords
                    .filter((kw) => kw.match === RubyKeywordMatchType.BROAD)
                    .map((kw) => kw.text),
                negativeKeywords: negativeKeywords.map((kw) => kw.text),
            };

            yield put(
                setCreationKeywordsAction(
                    keywords.seedKeywords,
                    // Filter out negatives from the targeting list to aid in validation
                    // There ideally shouldn't be any cross over, but in practice users keep managing to do it.
                    keywords.targetingKeywords.filter((kw) => !keywords.negativeKeywords.includes(kw)),
                    keywords.targetingBroadKeywords.filter((kw) => !keywords.negativeKeywords.includes(kw)),
                    keywords.negativeKeywords,
                    true
                )
            );
        }
    }
}

export default function* creationSaga() {
    yield* takeRequests(ActionName.CREATE_CAMPAIGN, createCampaignSaga);
    yield* takeRequests(ActionName.CREATE_EXPERIMENT, createExperimentSaga);
    yield takeEvery(ActionName.BROADCAST_MESSAGE, externalNewCampaign);
    yield takeEvery(ActionName.RESET_CREATION, clearCreationState);
    yield takeLatest(ActionName.SET_CREATION_STEP, storeCreationState);
    yield* takeRequests(ActionName.RESTORE_CREATION_STATE, loadCreationState);
    yield takeEvery(ActionName.LOGOUT, triggerClearCreationState);
    yield takeLatest(ActionName.SET_CREATION_TEAM, populateCampaignDetails);
}
