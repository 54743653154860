import { matchEventQuery, matchKeywordRanking, matchKeywordSummary } from "@/selectors/matchers";
import moment from "moment";
import { ActionName, type ActionType } from "../actions/ActionType";
import { selectActiveCountries } from "../selectors/campaignSelectors";
import { type RubyPurpose } from "../services/backend/RubyData";
import { createEventsReport, type EventReport } from "../utilities/events";
import {
    calculateAdditionalMetrics,
    extractRegionsReport,
    type MetricsReport,
    type TemporalKeywordMetricsReport,
} from "../utilities/metrics";
import { ReportGranularity } from "../utilities/time";
import { getUrl } from "../utilities/url";
import { type ReportsState, type State } from "./domain";
import {
    requestMetricsReportActionReducer,
    requestReportActionReducer,
    requestSearchTermReportActionReducer,
} from "./requestReducer";

export const initialReportsState: ReportsState = {
    graphedMetrics: ["installs", "costPerDownload", "conversionRate"],
    graphedEventStats: ["events", "revenue", "revenuePerEvent"],
    regionFilter: [],
    eventTimeFilter: "period",
    summary: {},
    series: {},
    keywordsSummary: {},
    keywordSummary: {},
    keywordSeries: {},
    keywordRankSeries: {},
    regions: {},
    eventListSummary: {},
    eventsSummary: {},
    eventsKeywords: {},
    eventsSeries: {},
    teamSummaries: {},
    purposeSummaries: {},
    searchTermsSummaries: {},
    eventsKeywordsSeries: {},
};

export default function reportsReducer(
    state: ReportsState = initialReportsState,
    action: ActionType,
    globalState: State
) {
    switch (action.type) {
        case ActionName.GET_METRICS_SUMMARY: {
            const teamId = action.payload.request.teamId;
            state.summary[teamId] = requestMetricsReportActionReducer(
                action,
                state.summary[teamId],
                calculateAdditionalMetrics
            );
            break;
        }

        case ActionName.GET_CAMPAIGN_SUMMARIES: {
            const teamId = action.payload.request.teamId;
            state.teamSummaries[teamId] = requestMetricsReportActionReducer(
                action,
                state.teamSummaries[teamId],
                (report) =>
                    report.map((report) => ({
                        ...calculateAdditionalMetrics(report.value),
                        campaignId: parseInt(report.key, 10),
                    }))
            );
            break;
        }

        case ActionName.GET_METRICS_SERIES: {
            const teamId = action.payload.request.teamId;
            state.series[teamId] = requestMetricsReportActionReducer(action, state.series[teamId], (report) => {
                return report.map((report) => ({
                    ...calculateAdditionalMetrics(report.value),
                    date: moment.utc(report.key).valueOf(),
                    granularity: ReportGranularity.HOURLY,
                }));
            });
            break;
        }

        case ActionName.GET_REGIONS_REPORT: {
            const teamId = action.payload.request.teamId;
            const allRegions =
                action.payload.request.country?.length > 0
                    ? action.payload.request.country
                    : selectActiveCountries(globalState, action.payload.request.campaignId).data?.activeCountries;
            state.regions[teamId] = requestMetricsReportActionReducer(action, state.regions[teamId], (report) =>
                extractRegionsReport(report, allRegions)
            );
            break;
        }

        case ActionName.GET_KEYWORDS_SUMMARY: {
            const teamId = action.payload.request.teamId;
            state.keywordsSummary[teamId] = requestMetricsReportActionReducer(action, state.keywordsSummary[teamId]);
            break;
        }

        case ActionName.GET_SEARCH_TERMS_SUMMARY: {
            const teamId = action.payload.request.teamId;
            state.searchTermsSummaries[teamId] = requestSearchTermReportActionReducer(
                action,
                state.searchTermsSummaries[teamId]
            );
            break;
        }

        case ActionName.SET_GRAPHED_METRICS: {
            state.graphedMetrics = action.payload;
            break;
        }

        case ActionName.LOGIN_SUCCESS: {
            const metadata = action.payload.user?.metadata ?? {};
            if ("graphedMetrics" in metadata) {
                state.graphedMetrics = metadata.graphedMetrics as (keyof MetricsReport)[];
            }
            if ("graphedEventStats" in metadata) {
                state.graphedEventStats = metadata.graphedEventStats as (keyof EventReport)[];
            }
            break;
        }

        case ActionName.SET_REGION_FILTER: {
            state.regionFilter = action.payload;
            break;
        }

        case ActionName.LOCATION_CHANGE: {
            const payload = action.payload;
            const isCampaignPage = payload.location.pathname.startsWith(
                getUrl.campaign(globalState.ui.activeTeam, globalState.ui.activeCampaign)
            );
            if (!isCampaignPage) {
                state.regionFilter = [];
            }
            break;
        }

        case ActionName.GET_KEYWORD_SUMMARY: {
            const teamId = action.payload.request.teamId;
            state.keywordSummary[teamId] = requestReportActionReducer(
                action,
                state.keywordSummary[teamId],
                matchKeywordSummary
            );
            break;
        }

        case ActionName.GET_KEYWORD_SERIES: {
            const teamId = action.payload.request.teamId;
            state.keywordSeries[teamId] = requestReportActionReducer(
                action,
                state.keywordSeries[teamId],
                matchKeywordSummary,
                (reports): TemporalKeywordMetricsReport[] => {
                    return reports.map(
                        (report): TemporalKeywordMetricsReport => ({
                            ...calculateAdditionalMetrics(report.value),
                            keywordId: action.payload.request.keywordId,
                            date: moment.utc(report.key).valueOf(),
                            granularity: ReportGranularity.HOURLY,
                        })
                    );
                }
            );
            break;
        }

        case ActionName.GET_KEYWORD_RANKS_SERIES: {
            const teamId = action.payload.request.teamId;
            state.keywordRankSeries[teamId] = requestReportActionReducer(
                action,
                state.keywordRankSeries[teamId],
                matchKeywordRanking
            );
            break;
        }

        case ActionName.GET_EVENT_LIST_SUMMERY: {
            const teamId = action.payload.request.teamId;
            state.eventListSummary[teamId] = requestReportActionReducer(
                action,
                state.eventListSummary[teamId],
                matchEventQuery,
                (reports) =>
                    reports.map((report) => ({
                        ...createEventsReport({ ...report, totals: report.value }),
                        name: report.key,
                    }))
            );
            break;
        }

        case ActionName.GET_EVENTS_SUMMARY: {
            const teamId = action.payload.request.teamId;
            state.eventsSummary[teamId] = requestReportActionReducer(
                action,
                state.eventsSummary[teamId],
                matchEventQuery,
                createEventsReport
            );
            break;
        }

        case ActionName.GET_EVENTS_KEYWORDS: {
            const teamId = action.payload.request.teamId;
            state.eventsKeywords[teamId] = requestReportActionReducer(
                action,
                state.eventsKeywords[teamId],
                matchEventQuery,
                (reports) => {
                    return reports.map((report) => ({
                        ...createEventsReport({ ...report, totals: report.value }),
                        keyword: report.key,
                    }));
                }
            );
            break;
        }

        case ActionName.GET_EVENTS_SERIES: {
            const teamId = action.payload.request.teamId;
            state.eventsSeries[teamId] = requestReportActionReducer(
                action,
                state.eventsSeries[teamId],
                matchEventQuery,
                (reports) => {
                    return reports.map((report) => ({
                        ...createEventsReport({ ...report, totals: report.value }),
                        date: moment.utc(report.key).valueOf(),
                        granularity: ReportGranularity.HOURLY,
                    }));
                }
            );
            break;
        }

        case ActionName.SET_GRAPHED_EVENT_STATS: {
            state.graphedEventStats = action.payload;
            break;
        }

        case ActionName.GET_EVENTS_SERIES_KEYWORDS: {
            const teamId = action.payload.request.teamId;
            state.eventsKeywordsSeries[teamId] = requestReportActionReducer(
                action,
                state.eventsKeywordsSeries[teamId],
                (req) => (payload) => matchEventQuery(req)(payload) && req.granularity === payload.granularity,
                (reports) => {
                    return reports.map((report) => ({
                        ...createEventsReport({ ...report, totals: report.value }),
                        keyword: report.key,
                        date: report.date,
                        granularity: action.payload.request.granularity,
                    }));
                }
            );
            break;
        }

        case ActionName.GET_PURPOSE_SUMMARIES: {
            const teamId = action.payload.request.teamId;
            state.purposeSummaries[teamId] = requestMetricsReportActionReducer(
                action,
                state.purposeSummaries[teamId],
                (reports) =>
                    reports.map((report) => ({
                        ...calculateAdditionalMetrics(report.value),
                        purpose: report.key as RubyPurpose,
                    }))
            );
            break;
        }

        case ActionName.SET_EVENT_TIME_FILTER: {
            state.eventTimeFilter = action.payload;
            break;
        }
    }
}
