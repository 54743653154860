import { isExperiment } from "@/selectors/campaignSelectors";
import { matchHistoryRecord } from "@/selectors/matchers";
import { CampaignObjectiveType, type CampaignObjective } from "@/types/CampaignObjective";
import { concatArray } from "@/utilities/array";
import { safeParseObj } from "@/utilities/obj";
import moment from "moment";
import { RequestActionState } from "../actions/Action";
import { ActionName, type ActionType } from "../actions/ActionType";
import { RubyBudgetPlanStatus, RubyCampaignStatus, type RubyBudgetPlan } from "../services/backend/RubyData";
import { extendBudgetPlans } from "../utilities/budget";
import { days } from "../utilities/time";
import { TimeFilterType, getTimeFilter } from "../utilities/timeFilter";
import { getUrl } from "../utilities/url";
import { CampaignSortOrder, CampaignView, CampaignVisibilityFilter, type CampaignsState, type State } from "./domain";
import {
    initialAsyncDataState,
    initialRequestState,
    requestActionReducer,
    requestReportActionReducer,
    resolvedAsyncDataState,
} from "./requestReducer";

const last30 = getTimeFilter(TimeFilterType.LAST_30);

export const initialCampaignsState: CampaignsState = {
    campaigns: {},
    teamCampaigns: {},
    search: "",
    searchDates: [last30.from.valueOf(), last30.to.valueOf()],
    searchSortOrder: CampaignSortOrder.CREATED_AT,
    searchVisibility: {
        [CampaignVisibilityFilter.STATUS]: true,
        [CampaignVisibilityFilter.BUDGET_REMAINING]: true,
    },
    campaignView: CampaignView.METRICS,
    searchAppFilter: [],
    channels: {},
    apps: {},
    productPages: {},
    regions: {},
    budgets: {},
    allocations: {},
    activeBudgets: {},
    events: {},
    teamEvents: {},
    funds: {},
    deleteCampaign: initialRequestState(),
    synchronisation: {},
    appleStatus: {},
    syncRequest: initialRequestState(),
    addFunds: initialRequestState(),
    historyRecords: {},
    editCampaign: initialRequestState(),
    experimentSelected: null,
    listData: {},
    eligibleImports: {},
    importStandaloneCampaign: initialRequestState(),
    updateImportedCampaign: initialRequestState(),
};

export default function campaignsReducer(
    state: CampaignsState = initialCampaignsState,
    action: ActionType,
    globalState: State
) {
    switch (action.type) {
        case ActionName.LOCATION_CHANGE: {
            const payload = action.payload;
            const campaignUrl = getUrl.campaign(globalState.ui.activeTeam, "CAMPAIGN_ID").replace(/CAMPAIGN_ID.*$/, "");
            const isCampaignPage = payload.location.pathname.startsWith(campaignUrl);
            const isFromCampaignPage = globalState.router.location.pathname.startsWith(campaignUrl);
            if (!isCampaignPage && !isFromCampaignPage) {
                state.search = "";
            }
            state.searchAppFilter = [];
            break;
        }

        case ActionName.LIST_CAMPAIGNS: {
            state.teamCampaigns[action.payload.request] = requestActionReducer(
                action,
                (campaigns) => campaigns.map((c) => c.id),
                state.teamCampaigns[action.payload.request]
            );
            if (action.payload.state === RequestActionState.SUCCESS) {
                action.payload.response.forEach((campaign) => {
                    state.campaigns[campaign.id] = {
                        errorMessage: null,
                        isRequesting: false,
                        success: true,
                        lastUpdated: Date.now(),
                        data: campaign,
                    };
                });
            }
            break;
        }

        case ActionName.GET_CAMPAIGN: {
            state.campaigns[action.payload.request.campaignId] = requestActionReducer(
                action,
                undefined,
                state.campaigns[action.payload.request.campaignId]
            );
            const isCampaignPage = globalState.router.location.pathname.startsWith(
                getUrl.campaign(action.payload.request.teamId, action.payload.request.campaignId)
            );
            if (action.payload.state === RequestActionState.SUCCESS && isCampaignPage) {
                const createdAt = action.payload.response.createdAt;
                state.searchDates = adjustSearchDates(createdAt, state.searchDates);
            }
            break;
        }

        case ActionName.GET_CAMPAIGNS_LIST_DATA: {
            state.listData[action.payload.request] = requestActionReducer(
                action,
                undefined,
                state.listData[action.payload.request]
            );
            break;
        }

        case ActionName.SEARCH_CAMPAIGNS: {
            state.search = action.payload;
            break;
        }

        case ActionName.GET_CHANNEL: {
            const id = action.payload.request.campaignId;
            state.channels[id] = requestActionReducer(action, undefined, state.channels[id]);
            break;
        }

        case ActionName.LIST_REGIONS: {
            const id = action.payload.request.campaignId;
            state.regions[id] = requestActionReducer(action, undefined, state.regions[id]);
            break;
        }

        case ActionName.GET_IOS_APP: {
            const id = action.payload.request.appId;
            state.apps[id] = requestActionReducer(action, undefined, state.apps[id]);
            break;
        }

        case ActionName.GET_IOS_APP_PRODUCT_PAGES: {
            const id = action.payload.request.appId;
            state.productPages[id] = requestActionReducer(action, undefined, state.productPages[id]);
            break;
        }

        case ActionName.LIST_TEAM_APPS: {
            if (action.payload.state === RequestActionState.SUCCESS) {
                action.payload.response.forEach((app) => {
                    if (!state.apps?.[app.trackId]?.success) {
                        state.apps[app.trackId] = {
                            errorMessage: null,
                            isRequesting: false,
                            success: true,
                            lastUpdated: Date.now(),
                            data: app,
                        };
                    }
                });
            }
            break;
        }

        case ActionName.LIST_BUDGET_PLANS: {
            state.budgets[action.payload.request] = requestActionReducer(
                action,
                (plans) => plans.filter((plan) => plan.status !== RubyBudgetPlanStatus.DISABLED),
                state.budgets[action.payload.request]
            );
            break;
        }

        case ActionName.GET_ACTIVE_BUDGET_PLAN: {
            state.activeBudgets[action.payload.request] = requestActionReducer(
                action,
                undefined,
                state.activeBudgets[action.payload.request]
            );
            break;
        }

        case ActionName.LIST_ALL_ALLOCATIONS: {
            const id = action.payload.request.campaignId;
            state.allocations[id] = requestActionReducer(action, undefined, state.allocations[id]);
            break;
        }

        case ActionName.ADD_BUDGET_PLAN: {
            if (action.payload.state === RequestActionState.SUCCESS) {
                state.budgets[action.payload.request.campaignId] ??= { ...initialAsyncDataState<RubyBudgetPlan[]>([]) };
                state.budgets[action.payload.request.campaignId].data = extendBudgetPlans(
                    concatArray(state.budgets?.[action.payload.request.campaignId]?.data, [action.payload.response])
                );
            }
            break;
        }

        case ActionName.REMOVE_BUDGET_PLAN: {
            if (action.payload.state === RequestActionState.SUCCESS) {
                const campaignId = action.payload.request.campaignId;
                const planIndex = state.budgets[campaignId]?.data.findIndex(
                    (plan) => plan.id === action.payload.request.id
                );
                state.budgets[campaignId]?.data?.splice(planIndex, 1);
                const activeBudget = state.activeBudgets[campaignId].data;
                if (activeBudget?.id === action.payload.request.id) {
                    state.activeBudgets[campaignId] = initialAsyncDataState();
                }
            }
            break;
        }

        case ActionName.UPDATE_BUDGET_PLAN: {
            if (action.payload.state === RequestActionState.SUCCESS) {
                const updatedPlan = action.payload.response;
                const campaignId = action.payload.request.campaignId;
                state.budgets[campaignId].data = extendBudgetPlans(
                    state.budgets?.[action.payload.request.campaignId]?.data?.map((plan) =>
                        plan.id === updatedPlan.id ? { ...plan, ...updatedPlan } : plan
                    )
                );
            }
            break;
        }

        case ActionName.SET_SEARCH_DATES: {
            state.searchDates = action.payload;
            break;
        }

        case ActionName.SET_SEARCH_ORDER: {
            state.searchSortOrder = action.payload;
            break;
        }

        case ActionName.SET_SEARCH_VISIBILITY: {
            state.searchVisibility[action.payload.filter] = action.payload.state;
            break;
        }

        case ActionName.SET_CAMPAIGN_VIEW: {
            state.campaignView = action.payload;
            break;
        }

        case ActionName.SET_ACTIVE_CAMPAIGN: {
            const createdAt = state.campaigns?.[action.payload.campaignId]?.data?.createdAt;
            state.searchDates = adjustSearchDates(createdAt, state.searchDates);
            break;
        }

        case ActionName.UPDATE_CHANNEL: {
            if (action.payload.state === RequestActionState.SUCCESS) {
                state.channels[action.payload.request.campaignId] = {
                    errorMessage: null,
                    isRequesting: false,
                    success: true,
                    lastUpdated: Date.now(),
                    data: action.payload.response,
                };
            }
            break;
        }

        case ActionName.CREATE_REGIONS: {
            if (action.payload.state === RequestActionState.SUCCESS) {
                const campaignId = action.payload.request.campaignId;
                state.regions[campaignId].data = action.payload.response;
            }
            break;
        }

        case ActionName.UPDATE_REGIONS: {
            if (action.payload.state === RequestActionState.SUCCESS) {
                const campaignId = action.payload.request.campaignId;
                if (!state.regions[campaignId]?.data) {
                    state.regions[campaignId].data = [];
                }

                const updatedRegions = action.payload.response;
                const existingRegions = state.regions[campaignId].data;

                updatedRegions.forEach((updatedRegion) => {
                    const existingRegionIndex = existingRegions.findIndex((r) => r.id === updatedRegion.id);
                    if (existingRegionIndex >= 0) {
                        existingRegions[existingRegionIndex] = updatedRegion;
                    }
                });
            }
            break;
        }

        case ActionName.UPDATE_REGION_WEIGHTINGS: {
            if (action.payload.state === RequestActionState.SUCCESS) {
                const campaignId = action.payload.request.campaignId;
                state.regions[campaignId] = {
                    errorMessage: null,
                    isRequesting: false,
                    success: true,
                    lastUpdated: Date.now(),
                    // As we are updating the region weightings, we update all regions
                    data: action.payload.response,
                };
            }
            break;
        }

        case ActionName.LIST_EVENTS: {
            const id = action.payload.request.campaignId;
            state.events[id] = requestActionReducer(action, undefined, state.events[id]);
            break;
        }

        case ActionName.LIST_TEAM_EVENTS: {
            state.teamEvents[action.payload.request] = requestActionReducer(
                action,
                undefined,
                state.events[action.payload.request]
            );
            break;
        }

        case ActionName.GET_CAMPAIGN_FUNDS: {
            const id = action.payload.request.campaignId;
            state.funds[id] = requestActionReducer(action, undefined, state.funds[id]);
            break;
        }

        case ActionName.ADD_CAMPAIGN_FUNDS: {
            state.addFunds = requestActionReducer(action);
            if (action.payload.state === RequestActionState.SUCCESS) {
                const campaignId = action.payload.request.campaignId;
                state.funds[campaignId].data ??= 0;
                state.funds[campaignId].data += action.payload.request.totalAmount;
            }
            break;
        }

        case ActionName.DELETE_EXPERIMENT:
        case ActionName.DELETE_CAMPAIGN: {
            state.deleteCampaign = requestActionReducer(action);
            if (action.payload.state === RequestActionState.SUCCESS) {
                const campaignId = action.payload.request.campaignId;
                const teamId = action.payload.request.teamId;
                const campaign = state.campaigns[campaignId];
                state.teamCampaigns[teamId].data = state.teamCampaigns[teamId].data.filter((cid) => cid !== campaignId);
                state.campaigns[campaignId].data.status = RubyCampaignStatus.DELETED;
                if (isExperiment(campaign?.data) && campaign.data.objective.campaignIdB) {
                    const campaignIdB = campaign.data.objective.campaignIdB;
                    state.teamCampaigns[teamId].data = state.teamCampaigns[teamId].data.filter(
                        (cid) => cid !== campaignIdB
                    );
                    state.campaigns[campaignIdB].data.status = RubyCampaignStatus.DELETED;
                }
            }
            break;
        }

        case ActionName.EDIT_CAMPAIGN: {
            state.editCampaign = requestActionReducer(action);
            if (action.payload.state === RequestActionState.SUCCESS) {
                state.campaigns[action.payload.request.campaignId].data.name = action.payload.request.name;
                state.campaigns[action.payload.request.campaignId].data.description =
                    action.payload.request.description;
                state.campaigns[action.payload.request.campaignId].data.objective = {
                    objectiveType: CampaignObjectiveType.AD_CAMPAIGN,
                    ...action.payload.request.objective,
                };
            }
            break;
        }

        case ActionName.GET_CAMPAIGN_APPLE_STATUS: {
            const id = action.payload.request.campaignId;
            state.appleStatus[id] = requestActionReducer(action, undefined, state.appleStatus[id]);
            break;
        }

        case ActionName.GET_CAMPAIGN_SYNCHRONISATION: {
            const id = action.payload.request.campaignId;
            state.synchronisation[id] = requestActionReducer(action, undefined, state.synchronisation[id]);
            break;
        }

        case ActionName.SYNCHRONISE_CAMPAIGN: {
            state.syncRequest = requestActionReducer(action);
            break;
        }

        case ActionName.LIST_CAMPAIGN_HISTORY_RECORDS: {
            const id = action.payload.request.campaignId;
            state.historyRecords[id] = requestReportActionReducer(
                action,
                state.historyRecords[action.payload.request.campaignId],
                matchHistoryRecord
            );
            break;
        }

        case ActionName.SET_APP_FILTER: {
            state.searchAppFilter = action.payload;
            break;
        }

        case ActionName.SET_ACTIVE_TEAM: {
            state.searchAppFilter = [];
            break;
        }

        case ActionName.SET_SELECTED_EXPERIMENT: {
            state.experimentSelected = action.payload;
            break;
        }

        case ActionName.GET_CAMPAIGNS_ELIGIBLE_FOR_IMPORT: {
            const teamId = action.payload.request;
            state.eligibleImports[teamId] = requestActionReducer(action, undefined, state.eligibleImports[teamId]);
            break;
        }

        case ActionName.IMPORT_STANDALONE_CAMPAIGN: {
            state.importStandaloneCampaign = requestActionReducer(action);

            if (action.payload.state === RequestActionState.SUCCESS) {
                const { campaign, channel, regions } = action.payload.response;
                const { teamId } = action.payload.request;
                state.campaigns[campaign.id] = resolvedAsyncDataState({
                    ...campaign,
                    teamId,
                    objective: safeParseObj<CampaignObjective>(campaign.objective),
                });
                state.channels[campaign.id] = resolvedAsyncDataState(channel);
                state.regions[campaign.id] = resolvedAsyncDataState(regions);
                state.teamCampaigns[teamId]?.data?.push(campaign.id);
            }
            break;
        }

        case ActionName.UPDATE_IMPORTED_CAMPAIGN: {
            state.updateImportedCampaign = requestActionReducer(action);
            break;
        }
    }
}

function adjustSearchDates(createdAt: number, searchDates: [number, number]): [number, number] {
    let [from, to] = searchDates;
    if (createdAt && createdAt > from) {
        from = moment.utc(createdAt).startOf("day").valueOf();
        if (to - from < days(1)) {
            to = moment.utc().endOf("day").valueOf();
        }
    }
    if (createdAt && createdAt > to) {
        to = moment.utc().endOf("day").valueOf();
    }
    return [from, to];
}
