import { CAMPAIGN_CARD_DIAL_SIZE } from "@/components/assemblies/campaignCard/constants";
import type { RubyCampaignId } from "@/services/backend/RubyData";
import { currencyFormatter } from "../../../../../utilities/currency";
import { languageString } from "../../../../../utilities/text";
import { useCampaignDataLoader } from "../../../../assemblies/campaignDataLoader/CampaignDataLoader";
import RequestLoader from "../../../../blocks/requestLoader/RequestLoader";
import StatRadial from "../../../../blocks/stats/radial/StatRadial";

interface BudgetRemainingDialProps {
    campaignId?: RubyCampaignId;
    size?: number;
}

export default function BudgetRemainingDial({ size = CAMPAIGN_CARD_DIAL_SIZE, campaignId }: BudgetRemainingDialProps) {
    const loader = useCampaignDataLoader(campaignId);
    const currency = loader.useCurrency();
    const budgetPacing = loader.useBudgetPacing();

    const formatCurrency = currencyFormatter(currency);

    return (
        <div className="u-relative">
            <RequestLoader
                request={budgetPacing}
                noPending
                placeholder={
                    <StatRadial
                        label={languageString("campaign.listing.budgetRemaining")}
                        value={0}
                        max={1000}
                        formatter={formatCurrency}
                        subLabel={languageString("campaign.budget.daysRemain", "", [0])}
                        size={size}
                    />
                }
                fallback={
                    <div className="u-fade2">
                        <StatRadial
                            label={languageString("campaign.listing.budgetRemaining")}
                            value={0}
                            max={1000}
                            formatter={formatCurrency}
                            subLabel={languageString("campaign.budget.daysRemain", "", [0])}
                            size={size}
                        />
                    </div>
                }
            >
                <StatRadial
                    label={languageString("campaign.listing.budgetRemaining")}
                    value={budgetPacing.data?.unspentBudget}
                    max={budgetPacing.data?.budget?.totalBudget ?? 0}
                    formatter={formatCurrency}
                    subLabel={languageString("campaign.budget.daysRemain", "", [
                        budgetPacing.data?.budget?.daysRemaining,
                    ])}
                    size={size}
                />
            </RequestLoader>
        </div>
    );
}
